
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import formatPhone from '../../utils/formatPhone';
import api from '../../api';

const ContactListAddDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [name, setName] = useSmoothState('', 0.5);

    useEffect(() => {
        if (!props.dialog) {
            setAlert('initial');
            setName('initial');
            return;
        }

        setAlert(props.dialog);
    }, [props.dialog]);

    const alertClass = conditional('ContactListAddDialog', style, { visible: props.dialog != null });
    const alertData = alert || {};

    const save = async () => {
        props.onFinish && props.onFinish(1);
        props.onSave(name);
    };

    const enabled = name.length > 2;

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>{alertData.description}</div>
                <div className={style.info}>
                    <input value={name} className={style.input + ' ip2'} placeholder="Name your group..." onChange={e => setName(e.target.value)} />
                </div>
                <div className={style.actions}>
                    <div className={'cancel'} onClick={() => props.onFinish && props.onFinish(0)}>Cancel</div>
                    <div className={(enabled) ? 'button' : 'button disabled'} onClick={save}>{alertData.cta || 'Save'}</div>
                </div>
            </div>
        </div>
    );

};

export default ContactListAddDialog;
