
import { useRef, useState, useEffect } from 'react';
import applyStyle from '../utils/applyStyle';

const useProp = prop => {
    const [value, setValue] = useState(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        prop && setValue(prop);
        setVisible(prop !== null);
    }, [prop]);

    return [value || {}, visible];
};

export const usePropRef = (prop, base, visible) => {
    const ref = useRef();
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (prop) {
            setValue(prop);
            ref.current && applyStyle({ ...base, ...visible }, ref.current);
        } else {
            ref.current && applyStyle({ ...base }, ref.current);
        }
    }, [prop]);

    return [value || {}, ref];
};

export default useProp;
