
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import formatPhone from '../../utils/formatPhone';
import api from '../../api';

const MediaAddDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [media, setMedia] = useSmoothState({ type: null, title: '', url: '', thumbnail: '' }, 0.5);

    useEffect(() => {
        if (!props.dialog) {
            setAlert('initial');
            setMedia('initial');
            return;
        }

        setAlert(props.dialog);
        setMedia({ ...media, ...props.dialog });
    }, [props.dialog]);

    const alertClass = conditional('MediaAddDialog', style, { visible: props.dialog != null });
    const alertData = alert || {};

    const updateMedia = field => e => {
        let value = e.target.value;
        setMedia({ ...media, [field]: value });
    };

    const enabled = media.type === 'link' ? (media.title && media.title.length) && (media.url && media.url.length) : (media.title && media.title.length);

    const descriptions = {
        photo: 'Amazing photo! Now give it a name and we\'ll put it in your library.',
        video: 'Sweet video! Now give it a name and we\'ll put it in your library.',
        link: 'Let\'s add your link! You can paste it in or type it here, then give it a name!'
    };

    const linkInfoClass = conditional('info', style, { hidden: media.type !== 'link' });

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>{descriptions[media.type]}</div>
                <div className={linkInfoClass}>
                    <input value={media.url} className={style.input + ' ip2'} placeholder="URL..." onChange={updateMedia('url')} />
                </div>
                <div className={style.info}>
                    <input value={media.title} className={style.input + ' ip2'} placeholder="Name..." onChange={updateMedia('title')} />
                </div>
                <div className={style.actions}>
                    <div className={'cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div>
                    <div className={(enabled) ? 'button' : 'button disabled'} onClick={() => props.onFinish && props.onFinish(media)}>{alertData.cta || 'Save'}</div>
                </div>
            </div>
        </div>
    );

};

export default MediaAddDialog;
