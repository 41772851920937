
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import api from '../../api';

import SegmentTabs from '../SegmentTabs';
import Collection from '../Collection';
import ConfirmDialog from '../ConfirmDialog';
import Empty from '../Empty';

import CampaignItem from '../CampaignItem';
import CampaignsEditor from '../CampaignsEditor';
import CampaignAddDialog from '../CampaignAddDialog';

import KeywordAddItem from '../KeywordAddItem';

const Campaigns = props => {

    const [campaigns, setCampaigns] = useState([]);

    const [campaignEditor, setCampaignEditor] = useState(null);
    const [addDialog, setAddDialog] = useState(null);
    const [confirmAlert, setConfirmAlert] = useSmoothState(null, 0.5);

    const [search, setSearch] = useState('');

    const [tabs, selectedTab, setSelectedTab, tabViews] = useTabSwitcher('page', style, ['All', 'Keyword']);
    
    useEffect(() => { fetchData() }, []);
    useEffect(() => { fetchData() }, [props.reloadNumber]);
    useEffect(() => { fetchData(selectedTab === 1) }, [selectedTab]);
    // TODO: dont have to refresh for this but gonna take some time to get great 

    const fetchData = async (keywordOnly = false) => {
        const results = await api.campaigns.fetch();
        const filteredResults = keywordOnly ? results.filter(result => result.keywords.length) : results;
        setCampaigns(filteredResults);
        console.log('fetchdata')
    };

    const addCampaign = async () => {
        const numbers = await api.numbers.fetch();
        const dialog = { description: 'Let\'s get your campaign going! Name your campaign and choose a number for it.', numbers };
        setAddDialog(dialog);
    };

    const saveCampaign = campaign => {
        const update = [campaign, ...campaigns];
        setCampaigns(update);
        setCampaignEditor(campaign);
    };

    const deleteCampaign = async campaign => {
        setConfirmAlert({ 
            description: 'Are you sure you want to delete this campaign? Your messages, keywords, and contacts will be deleted.', 
            cta: 'Delete',
            willConfirm: async () => {
                await api.campaigns.remove(campaign);
                fetchData();
            }
        });
    }

    return (
        <div className={style.Campaigns}>
            <div className={style.content}>
                <div className={style.topBar}>
                    <div className={style.title + ' t1'}>Campaigns</div>
                    <div className={style.add} onClick={addCampaign} />
                </div>

                <SegmentTabs selectedTab={selectedTab} tabs={tabs} onSelect={setSelectedTab} />

                <div className={style.campaigns}>
                    <div className={tabViews[0]}>
                        <Empty 
                            content={campaigns}
                            icon="messages"
                            title="No Campaigns Yet"
                            message="Looks like there's nothing here. Get started by adding a new campaign!"
                            cta="New Campaign"
                            onSelect={addCampaign} />
                        {campaigns.length ? <KeywordAddItem inline onSelect={() => addCampaign()} /> : null}
                        {campaigns.length ? <input className={style.search} placeholder="Search" onChange={e => setSearch(e.target.value)} /> : null}
                        <Collection
                            itemLayout
                            items={campaigns.filter(campaign => search.length < 0 || campaign.name.toLowerCase().includes(search.toLowerCase()))}
                            component={CampaignItem}
                            onAction={(item, action) => action === 'delete' && deleteCampaign(item._id)} 
                            onSelect={campaign => setCampaignEditor(campaign)} />
                        <div style={{height: '20px'}} />
                    </div>
                </div>
            </div>
            
            <CampaignsEditor campaign={campaignEditor} onFinish={() => {setCampaignEditor(null);fetchData(selectedTab === 1)}} onSave={() => console.log('save')} />
            {addDialog ? <CampaignAddDialog dialog={addDialog} onFinish={() => setAddDialog(null)} onSave={saveCampaign} /> : null}
            {confirmAlert ? <ConfirmDialog alert={confirmAlert} onFinish={() => setConfirmAlert(null)} /> : null}

{/*
            <SectionTitle title={typeData.title} />

            <Collection
                gridLayout
                component={CampaignItem}
                footerComponent={AddFooter}
                items={campaigns}
                refresh={() => fetchData()}
                onSelect={setShowingCampaign}
                onSelectFooter={() => setAddingCampaign({})} />

            <CampaignsShow
                profile={props.profile}
                campaign={showingCampaign}
                loading={props.loading}
                contacts={contacts}
                editContacts={campaign => setContactsCampaign(campaign)}
                onBack={() => {
                    setShowingCampaign(null)
                    fetchData();
                }}
                onAddMessage={message => { setAddingMessage(message) }}
                onRefreshed={_ => { setRefreshCampaign(false) }}
                shouldRefresh={refreshCampaign}
                error={props.error}
                history={props.history} />

            {addingCampaign ?
                <CampaignsAdd
                    profile={props.profile}
                    error={props.error}
                    showsKeyword={type === 'keyword'}
                    typeData={typeData}
                    adding={addingCampaign}
                    onBack={() => setAddingCampaign(null)}
                    onFinish={campaign => {
                        setAddingCampaign(null)
                        fetchData();
                        // console.log('Added campaign', campaign)
                    }} />
                : null}

            {showingCampaign ?
                <MessageAdd
                    campaign={showingCampaign}
                    error={props.error}
                    adding={addingMessage}
                    profile={props.profile}
                    onBack={() => setAddingMessage(null)}
                    onFinish={message => {
                        setAddingMessage(null)
                        setRefreshCampaign(true)
                        // console.log('Added message', message)
                    }}
                />
                : null}
            <ContactManager
                campaign={contactsCampaign}
                onClose={() => setContactsCampaign(null)}
                onUpdateContacts={contacts => setContacts(contacts)} />
                */}
        </div>
    );

};

export default Campaigns;
