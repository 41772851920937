
import React, { useRef, useEffect, useState } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

let firstScrollY = 0;

const Modal = props => {

    const ref = useRef();
    const scrollBottom = useRef();
    const [scrolled, setScrolled] = useState(false);

    const scrollToTop = () => {
        if (!ref || !ref.current) return;
        ref.current.scrollTop = 0;
    };

    useEffect(() => { 
        if (props.visible) {
            firstScrollY = window.scrollY;
            scrollToTop();
        }

        if (!props.visible) {
            window.scrollTo(0, firstScrollY);
            setTimeout(() => scrollToTop(), 500);
        }
    }, [props.visible]);

    useEffect(() => {
        if (!props.scroll) return;
        if (scrollBottom.current) scrollBottom.current.scrollIntoView({ behavior: 'smooth' });
    }, [props.scroll]);

    const scrollContent = () => {
        const shouldScroll = ref.current.scrollTop > 0 ? true : false;
        if (scrolled !== shouldScroll) setScrolled(shouldScroll);
    };

    const modalClass = conditional('Modal', style, props);
    const topBarClass = conditional('topBar', style, { scrolled });

    return (
        <div className={modalClass + ' background'}>
            <div className={style.content + (props.scrolls ? ' ' + style.scrolls : '')} ref={ref} onScroll={scrollContent}>
                {props.children}
                {props.scroll ? <div ref={scrollBottom} /> : null}
            </div>
            <div className={topBarClass}>
                <div className={style.back} onClick={() => { props.onFinish && props.onFinish() }} />
                <div className={style.title + ' t1'}>{props.navigationTitle}</div>
                {props.accessory ? <div className={style.accessory} onClick={props.accessory} /> : null}
                {props.add ? <div className={style.add} onClick={props.add} /> : null}
            </div>
        </div>
    );

};

export default Modal;
