
import React from 'react';
import conditional from '../../utils/conditional';
import Empty from '../Empty';
import style from './style.module.css';

const InsufficientCreditsWall = props => {

    return (
        <div className={conditional('InsufficientCreditsWall', style, { visible: props.visible })}>
            <Empty 
                content={[]}
                icon="credits"
                title="C"
                message="Your customers are not recieving your messages because you're out of credits. Upgrade your package to keep going."
                cta="Upgrade Subscriptions"
                onSelect={props.onSelect} />
        </div>
    );

};

export default InsufficientCreditsWall;
