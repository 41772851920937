
import React from 'react';
import style from './style.module.css';
import formatPhone from '../../utils/formatPhone';

const KeywordItem = props => {

    return (
        <div className={style.KeywordItem}>
            <div className={style.info}>
                <div className={style.titleContainer}>
                    <div className={style.title + ' it1'}>{props.data.keyword.toUpperCase()}</div>
                </div>
                <div className={style.description + ' it2'}>{formatPhone(props.data.phone)}</div>
            </div>
            <div className={style.icon} onClick={props.onAction('delete')} />
        </div>
    );

};

export default KeywordItem;
