
import axios from 'axios';
import authStorage from '../utils/authStorage';
import mockApi from '../utils/mockApi';

import auth from './auth';
import numbers from './numbers';
import campaigns from './campaigns';
import medias from './medias';
import lists from './lists';
import analytics from './analytics';
import conversations from './conversations';
import pages from './pages';
import tags from './tags';
import subscriptionManager from './subscriptionManager';

export default { auth, numbers, campaigns, medias, lists, analytics, conversations, pages, tags, subscriptionManager };

// MARK: Convenience

const urls = {
    'development': 'https://ttg-dev.geekyinteractive.com',
    'production': 'https://ttg-dev.geekyinteractive.com',
    'staging': 'https://ttg-dev.geekyinteractive.com'
};

export const request = async (route, data = {}) => {
    const url = urls[process.env.NODE_ENV] || urls['development'];
    
    if (!url) {
        const error = new Error('Error performing network request');
        error.code = 'url-invalid';
        throw error;
    }

    let options = null;

    try {
        const token = localStorage.getItem('jwt');
        if (token) options = { headers: { 'Authorization': token } };
    } catch (e) {
        const error = new Error('Error performing network request');
        error.code = 'auth-invalid';
        throw error;
    }

    try {
        const result = await axios.post(url + route, data, options);

        if (!result.data) {
            const error = new Error('Error performing network request');
            error.code = 'response-data-invalid';
            throw error;
        }

        return result.data;
    } catch (e) {
        const code = (((e || {}).response || {}).data || {}).code || 'connection-error';

        const error = new Error('Error performing network request');
        error.code = code;
        throw error;
    }
};

export const storage = authStorage;
export const mock = mockApi;
