
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const Button = props => {

    const buttonClass = conditional('Button', style, props);

    return (
        <div className={'cta' + (props.disabled ? ' disabled' : '')} onClick={props.onSelect}>
            <div className={style.label}>{props.sending ? 'Importing...' : 'Import Contacts'}</div>
            <div className={style.check} />
        </div>
    );

};

export default Button;
