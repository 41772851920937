
import React from 'react';
import style from './style.module.css';
import formatPhone from '../../utils/formatPhone';

const ContactManagerItem = props => {

    return (
        <div className={style.ContactManagerItem}>
            <div className={style.info}>
                <div className={style.titleContainer}>
                    <div className={style.title + ' it1'}>{props.data.firstName + ' ' + props.data.lastName}</div>
                    <div className={style.company + ' it2'}>{props.data.company || ''}</div>
                </div>
                <div className={style.description + ' it2'}>{props.data.unsubscribed ? 'Unsubscribed' : formatPhone(props.data.phone)}</div>
            </div>
            {props.hideDelete ? null : <div className={style.icon} onClick={props.onAction('delete')} />}
        </div>
    );

};

export default ContactManagerItem;
