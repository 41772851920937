
import React, { useEffect } from 'react';

import getLinkCode from '../../utils/getLinkCode';
import api from '../../api';

const LinkEntry = props => {

    const openPage = async () => {
        const code = getLinkCode(props);
        
        try {
            await api.pages.open(code);
        } catch (e) {
            // console.log('Could not track page open');
        }

        window.location.replace(props.data.url);
    };

    useEffect(() => { openPage() }, []);
    return <div />;

};

export default LinkEntry;
