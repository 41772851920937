
import React, { useState } from 'react';
import style from './style.module.css';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import SegmentTabs from '../SegmentTabs';
import Collection from '../Collection';
import Empty from '../Empty';
import MediaManagerItem from '../MediaManagerItem';
import KeywordAddItem from '../KeywordAddItem';
import storage from 'storage-dev';
import api from '../../api';
import resize from '../../utils/resize';
import thumbnailUtil from '../../utils/thumbnail';

storage.config = {
    appId: 't2g',
    accessToken: 'ti3T0l1Jq8K2aQ5FaY9IV62AEF29cBE/ZeLfy0B85ovmbu8/ddQBBnwF5suJ7qrt'
};

const MediaManager = props => {

    const [tabs, selectedTab, setSelectedTab, tabViews] = useTabSwitcher('page', style, ['All', 'Link', 'Photo', 'Video']);

    const [banner, setBanner] = useState(null);
    const [progress, setProgress] = useState(0);

    const changeFile = async e => {
        const file = e.target.files[0];
        if (!file) return;

        const components = file.name.split('.');
        const extension = components.length > 1 ? components[components.length - 1].toLowerCase() : '';
        const filename = (Math.random().toString(36).split('.')[0]) + '.' + extension;

        // if (!['png', 'jpg', 'jpeg'].includes(extension)) {
            
        //     return;
        // }

        console.log(extension);

        const type = ['mov', 'mp4', 'm4v'].includes(extension) ? 'video' : 'photo';

        try {
            const result = await storage.upload(file, filename, progress => {setProgress(parseInt((progress.progress * 100) - 1))});
            
            const thumbnail = type === 'video' ? await thumbnailUtil(file) : await resize(file, { width: 640 });
            const thumbnailResult = thumbnail ? await storage.upload(thumbnail, 'thumb_' + filename.split('.')[0] + '.jpg', progress => {}) : null;

            const add = await api.medias.add({ type, url: result.downloadUrl, thumbnail: thumbnailResult ? thumbnailResult.downloadUrl : result.downloadUrl, title: file.name });
            props.onReload();
            props.onSelect(add);

            setBanner(null);
            setTimeout(() => { setProgress(0) }, 500);
        } catch (e) {
            console.log(e);
            setBanner('Oops, we could not seem to upload this file. Make sure it is a .jpg or .mp4 file under 100 mb and try again.');
            setProgress(0);
        }
    };

    return (
        <div className={style.MediaManager}>
            <SegmentTabs selectedTab={selectedTab} tabs={tabs} onSelect={setSelectedTab} />
            <div className={tabViews[0]}>

            
            {/* <div className={style.addContacts}>
                                <div className={style.addIcon} />
                                <div className={style.title}>Add Media</div>
                                <div className={style.desc}>Drag and drop a file here to upload</div>
                                <input type="file" onChange={changeFile} />
                            </div> */}
            <KeywordAddItem type="upload" types=".jpg,.jpeg,.mov,.mp4,.m4v" onChangeFile={changeFile} />
            
            {progress > 0 && progress < 100 ? <div className={style.progress}>
                <div className={style.progressLabel}>Uploading {progress}%</div>
                <div className={style.progressBar} style={{ width: `${progress}%` }} />
            </div> : null}

            {banner ? <div className={style.banner}>{banner}</div> : null}
                {/* <Empty 
                    content={props.medias}
                    icon="media"
                    title="Media Empty"
                    message="Looks like there's nothing here. Upload media or add a link to get started!"
                    cta="Add Media" 
                    onSelect={() => props.onAdd()} /> */}
                <Collection
                    listLayout
                    items={props.medias}
                    component={MediaManagerItem}
                    onSelect={props.onSelect} />
            </div>
            <div className={tabViews[1]}>
                <Collection
                    listLayout
                    items={props.medias.filter(media => media.type === 'link')}
                    component={MediaManagerItem}
                    onSelect={props.onSelect} />
            </div>
            <div className={tabViews[2]}>
                <Collection
                    listLayout
                    items={props.medias.filter(media => media.type === 'photo')}
                    component={MediaManagerItem}
                    onSelect={props.onSelect} />
            </div>
            <div className={tabViews[3]}>
                <Collection
                    listLayout
                    items={props.medias.filter(media => media.type === 'video')}
                    component={MediaManagerItem}
                    onSelect={props.onSelect} />
            </div>
        </div>
    );

};

export default MediaManager;
