
import React, { useRef, useEffect } from 'react';

import getLinkCode from '../../utils/getLinkCode';
import api from '../../api';

let lastTime = 0;

const VideoEntry = props => {

    const video = useRef();

    const openPage = async () => {
        const code = getLinkCode(props);
        
        try {
            await api.pages.open(code);
        } catch (e) {
            // console.log('Could not track page open');
        }
    };
    
    const configureVideo = () => setInterval(() => { updateTime() }, 5000);

    const updateTime = async () => {
        if (!video.current) return;
        if (lastTime === video.current.currentTime) return;

        const code = getLinkCode(props);
        lastTime = video.current.currentTime;
        await api.pages.watch(code, lastTime);
    };

    useEffect(() => { 
        openPage();
        configureVideo();
    }, []);

    return (
        <div>
            <h2>{props.data.title}</h2>
            <video ref={video} controls src={props.data.url} />
        </div>
    );

};

export default VideoEntry;
