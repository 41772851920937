
import React from 'react';
import style from './style.module.css';
import formatPhone from '../../utils/formatPhone';
import ago from '../../utils/ago';

const MediaAnalyticsItem = props => {

    const contact = props.data.contact || {};
    const campaign = props.data.campaign ? props.data.campaign.name : null;
    const name = ((contact.firstName || '') + ' ' + (contact.lastName || ''));
    const opened = props.data.opened ? ago(props.data.opened) : null;
    const watched = props.data.watched;

    const watchTime = () => {
        const seconds = ((watched || {}).watchData || {}).seconds || 0;
        const minutes = Math.floor(seconds / 60);
        const mSeconds = minutes * 60;
        const remaining = seconds - mSeconds;
        const remainingSeconds = remaining.toFixed(0).length === 1 ? '0' + remaining.toFixed(0) : remaining.toFixed(0);
        return minutes > 0 ? minutes.toFixed(0) + 'm ' + remainingSeconds + 's' : remainingSeconds + 's';
    };

    // extra data in here is gonna be another scope
    return (
        <div className={style.MediaAnalyticsItem} onClick={props.onSelect}>
            {/* <div className={style.icon} style={{ backgroundImage: 'url(\'media_' + props.data.type + '.svg\')' }} /> */}
            <div className={style.info}>
                <div className={style.title + ' it1'}>{name.length > 1 ? name : formatPhone(contact.phone)}</div>
                {/* {campaign ? <div className={style.campaign + ' it2'}>from {props.data.campaign.name}</div> : null} */}
                {opened ? <div className={style.description + ' it2'}>opened {opened} {(opened.length > 5 || opened === 'now') ? '' : ''}</div> : <div className={style.description + ' it2'}>Not opened</div>}
                {watched ? <div className={style.description + ' it2'}>watched for {watchTime()}</div> : null}
            </div>
        </div>
    );

};

export default MediaAnalyticsItem;
