
const thumbnail = file => new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    document.body.appendChild(canvas);

    const video = document.createElement('video');
    video.style.display = 'none';
    video.setAttribute('crossorigin', 'anonymous');
    video.src = URL.createObjectURL(file);
    document.body.appendChild(video);

    const contextError = new Error('Error configuring canvas');
    contextError.code = 'canvas-error';

    const context = canvas.getContext('2d');
    if (!context) reject(contextError);

    video.addEventListener('loadedmetadata', () => {
        canvas.width = video.videoWidth / 2;
        canvas.height = video.videoHeight / 2;

        video.addEventListener('loadeddata', () => {
            video.currentTime = 0.1;
            setTimeout(() => {
                context.drawImage(video, 0, 0, video.videoWidth / 2, video.videoHeight / 2);
                canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7);
            }, 500);
        });
    });

    video.currentTime = 0.1;
    video.load();
});

export default thumbnail;
