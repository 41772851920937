
import { request } from './';

const fetch = async () => await request('/campaigns/fetch', {});
const add = async name => await request('/campaigns/add', { type: 'hybrid', name });
const remove = async campaign => await request('/campaigns/delete', { campaign });

const configureNumber = async (campaign, number) => await request('/campaigns/configure', {
    campaign,
    configuration: { number }
});

const addKeyword = async (campaign, keyword) => await request('/campaigns/configure', {
    campaign,
    configuration: { addKeyword: keyword }
});

const removeKeyword = async (campaign, keyword) => await request('/campaigns/configure', {
    campaign,
    configuration: { removeKeyword: keyword }
});

const fetchMessages = async campaign => await request('/messages/fetch', { campaign });

const addMessage = async (campaign, message) => await request('/messages/add', {
    campaign,
    method: message.schedule ? 'scheduled' : 'sequence',
    type: message.media ? 'media' : 'text',
    message: message.message,
    media: message.media ? message.media._id : 'default',
    timing: message.schedule ? message.schedule : message.sequence,
});

const removeMessage = async (campaign, message) => await request('/messages/delete', { campaign, message });

const fetchContacts = async campaign => await request('/campaigns/contacts/fetch', { campaign });

const addContacts = async (campaign, contacts) => {
    const contactsData = typeof contacts === 'object' ? { data: contacts } : { list: contacts };
    return await request('/campaigns/contacts/add', { campaign, contacts: contactsData });
};

const removeContacts = async (campaign, contacts) => await request('/campaigns/contacts/delete', { campaign, contacts });









const fetchBroadcast = async () => await request('/campaigns/broadcasts/fetch');
const fetchMessage = async data => await request('/messages/fetch', data);

const fetchKeyword = async data => {
    const result = await request(`/campaigns/fetch`);

    // i dunno what this is prob can delete
    let usedKeyword = result.find(c => {
        return c.keyword === data
    });
    
    if (usedKeyword) {
        return true
    }
    return false
    // console.log(result.data)
    // return result.data;
};

const postCampaign = async data => await request('/campaigns/add', data);
const postBlastEvent = async data => await request('/campaigns/broadcasts/add', data);
const deleteCampaign = async data => await request('/campaigns/delete', data);
const postMessage = async data => await request('/messages/add', data);

const deleteMessage = async data => await request('/messages/delete', data);
// const addContacts = async (campaign, contacts) => await request('/campaigns/contacts/add', { campaign, contacts });
const deleteContactByPhone = async (campaign, phone) => await request('/campaigns/contacts/delete', { campaign, phones: [phone] });
// const fetchContacts = async campaign => await request('/campaigns/contacts/fetch', { campaign });

export default { 
    fetch,
    add,
    remove,
    configureNumber,
    addKeyword,
    removeKeyword,
    fetchMessages,
    addMessage,
    removeMessage,
    fetchContacts,
    addContacts,
    removeContacts,


    // fetch, 
    postCampaign, 
    fetchKeyword, 
    fetchMessage, 
    postMessage, 
    deleteCampaign, 
    deleteMessage,
    // addContacts,
    // fetchContacts,
    deleteContactByPhone,
    fetchBroadcast,
    postBlastEvent
};
