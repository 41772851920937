
import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.css';
import { useConversations } from '../../utils/chatClient';
import Collection from '../Collection';
import Empty from '../Empty';

import api from '../../api';

import ConversationItem from '../ConversationItem';
import ConversationChat from '../ConversationChat';

const Conversations = props => {

    const [conversations, selectedConversation, setSelectedConversation, read, update, unreads] = useConversations();

    let readCount = 0;

    const send = async text => {
        if (!selectedConversation) return;
        return await api.conversations.send(selectedConversation, text);
    };

    const selectConversation = async conversation => {
        setSelectedConversation(conversation);
        readCount -= conversation.unreadCount;
        props.updateRead(readCount);
        read(conversation);
    };


    useEffect(() => {
        if (!conversations.length) return;

        readCount = conversations.reduce((a, c) => a + c.unreadCount, 0);
        props.updateRead(readCount);
        // setSelectedConversation(conversations[0]);
    }, [conversations]);

    useEffect(() => {
        if (!conversations.length) return;

        readCount = conversations.reduce((a, c) => a + c.unreadCount, 0);
        props.updateRead(readCount);
        // setSelectedConversation(conversations[0]);
    }, [update]);

    return (
        <div className={style.Conversations}>
            <div className={style.content}>
                <div className={style.topBar}>
                    <div className={style.title + ' t1'}>Inbox</div>
                    {/* <div className={style.add} onClick={addConversation} /> this isnt supported yet */}
                </div>

                <div className={style.columns}>
                <div className={style.conversations}>
                    <div className={style.page}>
                        <Empty 
                            content={conversations}
                            icon="inbox"
                            title="Inbox Empty"
                            message="Looks like there's nothing here. Come back often to check your replies!" />
                        <Collection
                            selectedId={(selectedConversation || {})._id}
                            listLayout
                            items={conversations}
                            component={ConversationItem}
                            onSelect={selectConversation} />
                    </div>
                </div>
                <div className={style.chatContainer}>
                    <ConversationChat container update={update} conversation={selectedConversation} onFinish={() => setSelectedConversation(null)} onSend={send} />
                </div>
                </div>
            </div>
            <ConversationChat update={update} conversation={selectedConversation} onFinish={() => setSelectedConversation(null)} onSend={send} />
        </div>
    );

};

export default Conversations;
