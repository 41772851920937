
import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import BlurModal from '../BlurModal';
import SegmentTabs from '../SegmentTabs';
import conditional from '../../utils/conditional';
import MediaManager from '../MediaManager';
import api from '../../api';
import ConfirmDialog from '../ConfirmDialog';
import Collection from '../Collection';
import Empty from '../Empty';
import ContactManagerItem from '../ContactManagerItem';
import formatPhone from '../../utils/formatPhone';
import ImportContactItem from '../ImportContactItem';
import SendButton from '../SendButton';

const followUps = [
    {
        number: '30',
        text: 'days later',
        // timing: { sendAfter: 60 }
        timing: { sendAfter: 30 * 86400 }
    },
    {
        number: '60',
        text: 'days later',
        timing: { sendAfter: 60 * 86400 }
    },
    {
        number: '90',
        text: 'days later',
        timing: { sendAfter: 90 * 86400 }
    }
];

const repeats = [
    {
        title: 'None',
        type: 'none',
        count: 1
    },
    // {
    //     title: 'debug',
    //     type: 'minutely',
    //     count: 5
    // },
    // {
    //     title: 'Daily',
    //     type: 'daily',
    //     count: 1825
    // },
    {
        title: 'Weekly',
        type: 'weekly',
        count: 260
    },
    {
        title: 'Monthly',
        type: 'monthly',
        count: 72
    },
    {
        title: 'Yearly',
        type: 'yearly',
        count: 5
    }
];

const ContactImporter = props => {

    const [importOptions, setImportOptions] = useSmoothState(null, 0.5);
    const [contacts, setContacts] = useSmoothState(null, 0.5);
    const [selectedContacts, setSelectedContacts] = useSmoothState([], 0.5);
    const [search, setSearch] = useSmoothState('', 0.5);
    const [showConfirm, setShowConfirm] = useSmoothState(false, 0.5);
    const [submitting, setSubmitting] = useSmoothState(false);
    const [sent, setSent] = useSmoothState(false);
    const [confirmAlert, setConfirmAlert] = useSmoothState(null, 0.5);

    useEffect(() => {
        if (!props.importOptions) {
            setImportOptions('initial');
            setContacts('initial');
            setSelectedContacts('initial');
            setConfirmAlert('initial');
            setSearch('initial');
            return;
        }

        setImportOptions(props.importOptions);
        fetchContacts();
    }, [props.importOptions]);

    const fetchContacts = async () => {
        const mainContacts = await api.lists.fetchMainContacts();
        setContacts(mainContacts);
    };

    const selectContact = selected => {
        setContacts(contacts.map(contact => ({ ...contact, selected: contact.phone === selected.phone ? !contact.selected : contact.selected })));
    };

    const save = async () => {
        // if (!props.importOptions._id) return;
        // const addedMessage = await api.campaigns.addMessage(props.importOptions._id, newMessage);

        const saveContacts = (contacts || []).filter(contact => contact.selected);

        props.onFinish && props.onFinish(1);
        props.onSave(saveContacts);
    };

    const secondary = props => (
        <div className={style.contentMedia}>
            
        </div>
    );

    const confirm = props => (
        <div className={style.contentConfirm}>
            {/* <div className={style.headline}>
                You're about to add {selectedContacts.length }
            </div>
            <div className={style.preview}>
                <div className={style.previewIcon}></div>
                <div className={style.previewInfo}>
                    <div className={style.previewTitle}>
                        <div className={style.previewNumber}>{number}</div>
                        <div className={style.previewTime}>now</div>
                    </div>
                    <div className={style.previewContent}>
                        <p className={style.previewMessage}>{message}</p>
                        {previewMedia()}
                    </div>
                </div>
            </div>
            <div className={style.contactsHeader}>
                This message will send to the following contacts and cost {creditText} in total when sent.
            </div>
            <div className={style.contacts}>
            <Collection
                listLayout
                items={props.contacts}
                component={ContactManagerItem}
                onSelect={() => {}} 
                hideDelete={true}
                onAction={(item, action) => {}} />
            </div>
            <div className={style.previewActions}>
                <div className={style.done + ((message.length) ? ' button' : ' button disabled')} onClick={() => save()}>{timingAction(props.timing)}</div>
            </div> */}
        </div>
    );

    const selected = (contacts || []).filter(contact => contact.selected);
    
    return (
        <BlurModal navigationTitle="Add Existing Contacts" confirmNavigationTitle="Ready To Send?" {...props} visible={props.importOptions} confirm={confirm} confirmVisible={showConfirm} onConfirmFinish={() => setShowConfirm(false)} onConfirmSave={save}>
            <div className={style.content}>
                <input className={style.input} value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} />
                <Empty 
                    content={contacts}
                    icon="contacts"
                    title="Contacts Empty"
                    message="Looks like there's nothing here yet. Your contacts will appear here as you add them!" />
                <Collection
                    listLayout
                    items={(contacts ? contacts : []).filter(contact => search.length ? ((contact.firstName || '') + (contact.lastName || '')).toLowerCase().includes(search.toLowerCase()) : true)}
                    component={ImportContactItem}
                    onSelect={selectContact} 
                    hideDelete={true}
                    onAction={(item, action) => {}} />
                    <div class={style.desktopSpacer} />
            </div>
            <div className={style.actions}>
                {/* <div className={style.cancel + ' cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div> */}
                <div className={style.done + ((sent || submitting || !selected.length) ? ' button disabled' : ' button')} onClick={save}>{'Next'}</div>
            </div>
            {confirmAlert ? <ConfirmDialog alert={confirmAlert} onFinish={() => setConfirmAlert(null)} /> : null}
        </BlurModal>
    );

};

export default ContactImporter;
