
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import formatPhone from '../../utils/formatPhone';

const KeywordAddDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [keyword, setKeyword] = useSmoothState('', 0.5);
    const [saving, setSaving] = useSmoothState(false, 0.5);

    useEffect(() => {
        if (!props.dialog) {
            setAlert('initial');
            setKeyword('initial');
            setSaving(false);
            return;
        }

        setAlert(props.dialog);
    }, [props.dialog]);

    const alertClass = conditional('KeywordAddDialog', style, { visible: props.dialog != null });
    const alertData = alert || {};

    const phoneText = alertData.number ? formatPhone(alertData.number.phone) : 'this number';
    const enabled = keyword.length > 3;

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>Choose a keyword that people can text to {phoneText}. When they do, they will begin recieving your messages.</div>
                <div className={style.info}>
                    <input value={keyword} className={style.inputLeft + ' ip2'} placeholder="Keyword" onChange={e => setKeyword(e.target.value.trim())} />
                </div>
                <div className={style.actions}>
                    <div className={'cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div>
                    <div className={(enabled && !saving) ? 'button' : 'button disabled'} onClick={() => props.onFinish && props.onFinish(keyword)}>{alertData.cta || 'Save'}</div>
                </div>
            </div>
        </div>
    );

};

export default KeywordAddDialog;
