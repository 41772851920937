
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const AlertDialog = props => {

    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (!props.alert) return;
        setAlert(props.alert);
    }, [props.alert]);

    const alertClass = conditional('AlertDialog', style, { visible: alert });

    const finish = () => {
        setAlert(null);
        props.onDone();
    };

    const confirm = async () => {
        setAlert(null);
        await props.alert.onConfirm();
    };

    const alertContent = () => alert ? (
        <div className={style.container}>
            <h3 className={style.title}>{props.alert.title}</h3>
            <p className={style.description}>{props.alert.description}</p>
            <div className={style.buttonContainer}>
                <div className={style.done} onClick={finish}>{props.alert.doneTitle || 'Done'}</div>
                {props.alert.onConfirm ? <div className={style.done} style={{ color: props.alert.confirmColor || 'rgba(255, 255, 255, 0.7)' }} onClick={confirm}>{props.alert.confirmTitle || 'Confirm'}</div> : null}
            </div>
        </div>
    ) : null;

    return (
        <div className={alertClass}>
            {alertContent()}
        </div>
    );

};

export default AlertDialog;
