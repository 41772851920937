
import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.css';
import { BrowserRouter, Route, withRouter, Redirect } from 'react-router-dom';

import conditional from '../../utils/conditional';
import bridge from 'bridge-request';

import TabBar from '../TabBar';
import Sidebar from '../Sidebar';
import AlertDialog from '../AlertDialog';

import SignIn from '../SignIn';
import SignUp from '../SignUp';
import SignOut from '../SignOut';
import CustomerEntry from '../CustomerEntry';

import Home from '../Home';
import Campaigns from '../Campaigns';
import Medias from '../AnalyticsBrowser';
import Conversations from '../Conversations';
import Analytics from '../Analytics';
import ComingSoon from '../ComingSoon';
import NumberManager from '../NumberManager';

import api from '../../api';
import alerts from '../../api/alerts';
import ManageSubscriptionsModal from '../ManageSubscriptionsModal';
import InsufficientCreditsWall from '../InsufficientCreditsWall';
import BillingDialog from '../BillingDialog';
import ConfirmDialog from '../ConfirmDialog';
import ContactLists from '../ContactLists';

const routes = [
    { path: '/', component: Home },
    // { path: '/broadcasts', component: ComingSoon },
    { path: '/campaigns', component: Campaigns },
    { path: '/contacts', component: ContactLists },
    // { path: '/keywords', component: Campaigns },
    { path: '/media', component: Medias },
    { path: '/conversations', component: Conversations },
    // { path: '/analyze', component: Analytics },
    // { path: '/qr-codes', component: ComingSoon },
    // { path: '/settings', component: ComingSoon },
    { path: '/sign-in', component: SignIn },
    { path: '/sign-up', component: SignUp },
    { path: '/sign-out', component: SignOut },
    // { path: '/campaigns/:action', component: Campaigns },
    // { path: '/media/:action', component: Medias },
    { path: '/l/:code', component: CustomerEntry }
];

// 134 - 147mb every tab set to home
// 180 with campaigns without overlays
// 145 with campaigns without overlays or collection and content
// 228 with the former with campaignseditor on

// /, /contacts, /media, /campaigns, /conversations

// TODO: This isnt great but works for now

const walls = {
    '/sign-in': SignIn,
    '/sign-up': SignUp,
    '/sign-out': SignOut,
    '/l': CustomerEntry
};

const disablePadding = {
    '/conversations': true
};

const Routes = props => {
    const renderedRoutes = routes.map(route => {
        const Component = route.component;
        const routeContent = conditional('routeContent', style, { visible: props.history.location.pathname === route.path });

        return (
            <div className={style.fakeAssRouteHolder}>
                <Route exact key={route.path} path={route.path} render={() => <div />} />
                <div className={routeContent}>
                    {/* <div /> */}
                    {/* {props.history.location.pathname === route.path ? ( */}
                        <Component 
                            error={props.error} 
                            loading={props.loading} 
                            profile={props.profile}
                            redirect={props.redirect}
                            history={props.history}
                            auth={props.auth}
                            user={props.user}
                            uploadingStatus={props.uploadingStatus}
                            reloadNumber={props.reloadNumber}
                            reloadUser={props.reloadUser}
                            requestActivity={props.requestActivity}
                            onAuthRequest={props.onAuthRequest}
                            updateRead={props.updateRead}
                            onOpen={props.onOpen} />
                </div>
            </div>
        );
    });

    const noPadding = disablePadding[props.history.location.pathname] ? ' ' + style.noPadding : '';

    return (
        <div className={style.Routes}>
            <div className={style.routeContainer + noPadding}>
                {renderedRoutes}
            </div>
        </div>
    );
    
};

const Container = withRouter(props => {

    // this component is gonna be a shitshow. should refactor soon

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [user, setUser] = useState(null);
    const [showNumbers, setShowNumbers] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [billingAlert, setBillingAlert] = useState(null);
    const [confirmAlert, setConfirmAlert] = useState(null);
    const [reloadNumber, setReloadNumber] = useState(null);
    const [showInsufficient, setShowInsufficient] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const [showUploading, setShowUploading] = useState(false);
    const [read, setRead] = useState(0);

    const billingCallback = useRef(() => {});
    const confirmCallback = useRef(() => {});

    const fetchUser = async (auth = null) => {
        try {
            const result = await api.auth.sso(auth);

            setUser(result);
            console.log(result)
            // updateCredits(result);

            if (localStorage.getItem('initialAuth')) {
                updateCredits(result);
            }

            localStorage.setItem('initialAuth', 'true');

            if (result.completed) {
                await api.subscriptionManager.configureAccount(result);


                if ((new Date(result.date)).getTime() < (new Date('2021-03-08')).getTime()) {
                    localStorage.removeItem('jwt');
                    const error = new Error('Account invalid with this version');
                    throw error;
                }

                props.history.push('/');
            }



            // if (localStorage.getItem('initialAuth')) {
            //     reloadUser();
            // }

            // localStorage.setItem('initialAuth', 'true');

            // opt ins/qr
            // media
        } catch (e) {
            handleError(e);
        }

        // await wait(50);

        // ugly hack until we refactor with Geeky router
        // for (const route of ['/contacts', '/campaigns', '/media', '/conversations', '/']) {
        //     await wait(100);
        //     props.history.push(route);
        // }

        setTimeout(() => {
            setShowSplash(false);
        }, 250);
    };

    const wait = time => new Promise(resolve => setTimeout(() => { resolve() }, time));

    const reloadUser = async () => {
        try {
            const result = await api.auth.sso(null);
            setUser(result);
            updateCredits(result);
        } catch (e) {
            handleError(e);
        }
    }

    const updateCredits = user => {
        console.log('checkinsufficiennt', user)
        setShowInsufficient(user.credits < 1);
        
        
        // people are not getting your messages. upgrade now to resume
    }

    const reloadNumbers = () => {
        setReloadNumber(Math.random().toString(36));
    };

    const updateUser = async (auth = null) => {
        try {
            const result = await api.auth.update(auth);
            setUser(result);

            if (result.completed) {
                await api.subscriptionManager.configureAccount(result);
                props.history.push('/');
            }
        } catch (e) {
            handleError(e);
        }
    };

    const finishSubscriptions = () => {
        setShowSubscriptions(null);
    };

    useEffect(() => { fetchUser() }, []);

    const handleError = e => {
        console.log(e);

        const alert = {
            title: 'Oops, something went wrong',
            description: e.message
        };
        
        setAlert(alert);
    };

    const requestActivity = activity => {
        if (activity === 'subscriptions') {
            console.log('show')
            setShowSubscriptions({});
        }

        if (activity === 'paymentNumber') {
            setBillingAlert({ 
                title: 'Payment Method Required', 
                description: 'Please add your payment information to purchase a new number', 
                cta: 'Purchase'
            });
        }
    };

    const Wall = props.location.pathname.includes('/l/') ? walls['/l'] : walls[props.location.pathname];

    if (Wall) return (
        <div className={style.wallContainer}>
            <Wall 
                history={props.history} 
                error={handleError}
                onAuthRequest={fetchUser}
                onSignUpRequest={updateUser} />
            <AlertDialog alert={alert} onDone={() => setAlert(null)} />
        </div>
    );

    if (!user) return <div className={style.wallContainer} />; // put loading here
    if (!user.completed) return <Redirect to="/sign-in" />;

    // TODO: this component is getin really hefty so we should switch to the context api soon

    const requestConfirmDialog = (alert, callback = null) => {
        setConfirmAlert(alert);
        confirmCallback.current = callback ? callback : () => {};
    };

    const requestBillingDialog = (alert, callback = null) => {
        setBillingAlert(alert);
        billingCallback.current = callback ? callback : () => {};
    };

    const finishConfirmPrompt = index => {
        setConfirmAlert(null);

        setTimeout(() => {
            confirmCallback.current(index);
        }, 500);
    };

    const finishBillingPrompt = index => {
        setBillingAlert(null);

        setTimeout(() => {
            billingCallback.current(index);
        }, 500);
    };

    const openLink = async url => {
        await bridge.request('openLink', { url });
        // window.open(url, '_blank');
    };

    return (
        <div className={style.container}>
            {/* <TitleBar 
                user={profile} 
                loading={loading} 
                onMenuToggle={() => setSidebarActive(true)}
                onClick={() => setSidebarActive(false)} /> */}

            <div className={style.content}>
                <Routes 
                    loading={setLoading} 
                    error={handleError} 
                    history={props.history}
                    user={user}
                    reloadUser={reloadUser}
                    reloadNumber={reloadNumber}
                    requestActivity={requestActivity}
                    requestConfirm={requestConfirmDialog}
                    requestBilling={requestBillingDialog}
                    uploadingStatus={status => setShowUploading(status)}
                    onAuthRequest={fetchUser}
                    onSignUpRequest={updateUser}
                    updateRead={read => setRead(read)}
                    onOpen={openLink} />
            </div>
            <div className={style.tabContainer}>
                <TabBar history={props.history} read={read} />
            </div>
            {/* <div /> */}
            {/* <TabBar history={props.history} /> */}
            {/* <NumberManager visible={showNumbers} onFinish={() => setShowNumbers(false)} /> */}
            {alert ? <AlertDialog alert={alert} onDone={() => setAlert(null)} /> : null}
            {showInsufficient ? <InsufficientCreditsWall visible={showInsufficient} onSelect={() => setShowSubscriptions(true)} /> : null}
            {showSubscriptions ? <ManageSubscriptionsModal reloadUser={reloadUser} reloadNumbers={reloadNumbers} subscription={showSubscriptions} requestActivity={requestActivity} requestConfirm={requestConfirmDialog} requestBilling={requestBillingDialog} onFinish={finishSubscriptions} /> : null}
            {billingAlert ? <BillingDialog alert={billingAlert} onFinish={finishBillingPrompt} /> : null}
            {confirmAlert ? <ConfirmDialog alert={confirmAlert} onFinish={finishConfirmPrompt} /> : null}
            <div className={conditional('splash', style, { visible: showSplash })} />
            <div className={conditional('uploading', style, { visible: showUploading })}>
                <div className={style.uploadingMessage}>Uploading media...</div>
            </div>
        </div>
    );

}); 

const App = props => (
    <div className={style.App}>
        <BrowserRouter>
            <Container authData={props.authData} />
        </BrowserRouter>
    </div>
);

export default App;
