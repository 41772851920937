
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';

const OptionMenu = props => {

    const [menu, setMenu] = useSmoothState(null, 0.5);

    useEffect(() => {
        if (!props.menu) {
            setMenu('initial');
            return;
        }

        setMenu(props.menu);
    }, [props.menu]);

    const selectOption = option => e => {
        e.stopPropagation();

        props.onFinish();
        option.onSelect();
    };

    const menuClass = conditional('OptionMenu', style, { visible: props.menu != null });
    const menuData = menu || [];

    const item = option => (
        <div className={style.option} onClick={selectOption(option)}>
            <div className={style.text + ' it2'}>{option.title}</div>
            {option.input ? <input className={option.input ? style.noMobile : ''} accept={props.types} type="file" onClick={e => e.stopPropagation()} onChange={e => props.onChangeFile && props.onChangeFile(e)} /> : null}
        </div>
    );

    return (
        <div className={menuClass} onClick={() => props.onFinish()}>
            <div className={style.container}>
                {menuData.map(item)}
            </div>
        </div>
    );

};

export default OptionMenu;
