
import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import { Link } from 'react-router-dom';

import { useTabSwitcher } from '../../utils/tabSwitcher';

import { totalUnreadCount } from '../../utils/chatClient';

import SegmentTabs from '../SegmentTabs';
import Collection from '../Collection';
import StatItem from '../StatItem';
import SplitView from '../SplitView';
import AccountItem from '../AccountItem';
import subscriptionManager from '../../api/subscriptionManager';
import api from '../../api';
import store from '../../api/store';

const shortcuts = [
    {
        title: 'Get Started',
        description: 'Send a message, kick off a new campaign and start connecting with your customers now!',
        path: '/campaigns/addCampaign',
        icon: 'add',
        animation: 'a'
    },
    {
        title: 'Manage Existing Campaigns',
        description: 'Create new content, view, revise and publish changes to your active campaigns.',
        path: '/campaigns',
        icon: 'campaign',
        animation: 'b'
    },
    {
        title: 'Analyze',
        description: 'Find out what your customers are clicking on and watching the most.',
        path: '/analyze',
        icon: 'analytics',
        animation: 'c'
    },
    {
        title: 'Media Library',
        description: 'This is a place to store all your photos, videos & links to share with your customers.',
        path: '/media',
        icon: 'movie',
        animation: 'd'
    }
];

const Shortcut = props => (
    <div className={style.box + ' ' + style[props.shortcut.animation]}>
        <Link to={props.shortcut.path}><div className={style.icon}><span className="material-icons" style={{ fontSize: '48px' }}>{props.shortcut.icon}</span></div></Link>
        <Link to={props.shortcut.path}><div className={style.title}>{props.shortcut.title}</div></Link>
        <Link to={props.shortcut.path}><div className={style.description}>{props.shortcut.description}</div></Link>
    </div>
);

const Home = props => {

    const profileUser = props.user || {};
    const profileName = (profileUser.firstName || '').trim() + ' ' + (profileUser.lastName || '').trim();
    const greeting = profileName + ' ✌️'; 
    // if u got 0 credits let ppl know nothings going out. [s2]

    const [tabs, selectedTab, setSelectedTab, tabViews] = useTabSwitcher('page', style, ['Get Started', 'Tips and Tricks']);

    const [stats, setStats] = useState([]);
    const [unreads, setUnreads] = useState(0);

    // const [conversations, selectedConversation, setSelectedConversation, read, update, totalUnreads] = useConversations();

    const shortcuts = [
        {
            category: 'Current Membership',
            title: 'Bronze Package',
            description: '1 Number + 100 Credits',
            link: '/conversations',
            icon: 'message'
        },
        {
            category: 'Your Numbers',
            title: '+1 (407) 283-7661',
            link: '/conversations',
            icon: 'message'
        },
        {
            category: 'Billing Info',
            title: '1200 Pearl StBoulder, CO 80302',
            link: '/conversations',
            icon: 'message'
        }
    ];

    const wait = time => new Promise(resolve => setTimeout(() => {
        resolve();
    }, time));

    const fetchCampaign = async () => {
        // const media = await api.medias.add({ type: 'link', title: 'Site Demo', url: 'https://geekyinteractive.com' });
        // console.log(media)

        let unreadCount = 0;

        const campaigns = await api.campaigns.fetch();
        const firstCampaign = campaigns[0];

        setStats([
            {
                category: 'Campaign',
                title: firstCampaign ? firstCampaign.name : 'Start A New Campaign',
                link: () => props.history.push('/campaigns'),
                icon: 'message'
            },
            {
                category: 'Inbox',
                title: 'No Unread Messages',
                link: () => props.history.push('/conversations'),
                icon: 'notification'
            },
            {
                category: 'Numbers',
                title: 'Manage Your Numbers',
                link: () => props.requestActivity('subscriptions'),
                icon: 'phone'
            },
            {
                category: 'Campaign',
                title: 'Message Customers',
                link: () => props.history.push('/campaigns'),
                icon: 'message'
            },
            {
                category: 'Groups',
                title: 'Manage Your Contacts',
                link: () => props.history.push('/contacts'),
                icon: 'contacts'
            },
            {
                category: 'Media',
                title: 'View Analytics Data',
                link: () => props.history.push('/media'),
                icon: 'media'
            }
        ]);

        setInterval(() => {
            if (totalUnreadCount === unreadCount) {
                return;

            }
            
            setStats([
                {
                    category: 'Campaign',
                    title: firstCampaign ? firstCampaign.name : 'Start A New Campaign',
                    link: () => props.history.push('/campaigns'),
                    icon: 'message'
                },
                {
                    category: 'Inbox',
                    title: totalUnreadCount > 0 ? totalUnreadCount + ' Unread Messages' : 'No Unread Messages',
                    link: () => props.history.push('/conversations'),
                    icon: 'notification'
                },
                {
                    category: 'Numbers',
                    title: 'Manage Your Numbers',
                    link: () => props.requestActivity('subscriptions'),
                    icon: 'phone'
                },
                {
                    category: 'Campaign',
                    title: 'Message Customers',
                    link: () => props.history.push('/campaigns'),
                    icon: 'message'
                },
                {
                    category: 'Groups',
                    title: 'Manage Your Contacts',
                    link: () => props.history.push('/contacts'),
                    icon: 'contacts'
                },
                {
                    category: 'Media',
                    title: 'View Analytics Data',
                    link: () => props.history.push('/media'),
                    icon: 'media'
                }
            ]);
        }, 500);

        

        // const convos = await api.conversations.fetch();
        // const unreadCount = conversations.reduce((a, convo) => a + convo.unreadCount, 0);

        

        const testing = document.getElementById('memtest');

        // for (let index = 0; index < 50; index++) {
            // await wait(5000);
            // const test = (array) => {
            //     array.push((new Array(1000000)).fill('test'));
            //   };
              
            //   const testArray = [];
              
            //   for(let i = 0; i <= 1000; i++) {
            //     test(testArray);
            //   }

              // other processes is gigs worth so investigate that i assume its webkit shit can just disable components in this app to figure out

            // (function(w){
            //     var cache = {}
            //     function getCachedThing(key) {
            //         if(!(key in cache)) {
            //             cache[key] = key;
            //         }
            //         return cache[key];
            //     }
            
            //     var i = 0;
            //     setInterval(function() {
            //         getCachedThing(i++);
            //     }, 100);
            //     w.getCachedThing = getCachedThing
            // })(window);

            // for (let index = 0; index < 1000000; index++) {
            //     // await wait(1);

            //     const testNode = document.createElement('div');
            //     testNode.style.background = 'red';
            //     testNode.style.width = '100px';
            //     testNode.style.height = '100px';
            //     testNode.innerText = 'Test' + index;
            //     testing.appendChild(testNode);
            //     testing.removeChild(testNode);


                // if (index % 10) console.log('in', index)
                // console.log(index)
    
                // if u can delay this and it works that means its cpu
            // }

            // if u can delay this and it works that means its cpu
        // }

    };

    useEffect(() => { fetchCampaign() }, []);
    useEffect(() => { fetchCampaign() }, [props.reloadNumber]);

    const selectTab = tab => {
        setSelectedTab(tab);

        if (selectedTab === tab && tab === 0) {
            props.history.push('/campaigns');
        }
    };

    return (
        <div className={style.Home}>
            <div className={style.logo} />
            <div className={style.content}>
                <div className={style.topBar}>
                    <div className={style.title + ' t1'}>Home</div>
                    <div className={style.credits} onClick={() => props.requestActivity('subscriptions')}>
                        <div className={style.creditText + ' t2'}>{Math.max(profileUser.credits || 0, 0)}</div>
                        <div className={style.creditIcon} />
                    </div>
                </div>
                <div className={style.greeting + ' h1'}>Hello,<br />{greeting}</div>
                
                {/* client may want this description but hope not lampoon */}
                {/* <div className={style.description + ' s1'}>Here you will find everything you’ll need to start connecting with your leads and customers!</div> */}
               
                <SegmentTabs selectedTab={selectedTab} tabs={tabs} onSelect={selectTab} />
                {/* {JSON.stringify(unreads)} */}

                <div className={style.stats}>
                    <div className={tabViews[0]}>
                        <Collection
                            gridLayout
                            items={stats}
                            component={StatItem}
                            onSelect={result => result.link()} />
                    </div>
                    <div className={tabViews[1]}>
                        {/* <Collection
                            listLayout
                            items={shortcuts}
                            component={AccountItem} /> */}
                    </div>
                </div>

                {/* <SplitView renders={[() => <div>YO</div>, () => <div>YO2</div>]} /> */}


                {/* <div className={style.content}>
                    {shortcuts.map(shortcut => <Shortcut key={shortcut.animation} shortcut={shortcut} />)}
                </div> */}

                
            </div>
        </div>
    );

};

export default Home;
