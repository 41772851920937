
import { useRef, useState, useEffect } from 'react';
import chat from 'chat-client';
import bridge from 'bridge-request';
import api from '../api';

export let totalUnreadCount = 0;

export const useConversations = () => {

    const subscribed = useRef(false);
    const activeConversationId = useRef(null);

    const [conversations, setConversations] = useState(null);
    const [updated, setUpdated] = useState(null);
    const [activeConversation, setActiveConversation] = useState(null);
    const [totalUnreads, setTotalUnreads] = useState(0);

    useEffect(() => {
        if (!conversations) return;
        
        const token = localStorage.getItem('jwt');
        if (!token) return;

        chat.subscribe(token, recieve);
        console.log('updated convos', conversations);

        totalUnreadCount = conversations.reduce((a, convo) => a + convo.unreadCount, 0);
        setTotalUnreads(conversations.reduce((a, convo) => a + convo.unreadCount, 0));
    }, [conversations]);

    useEffect(() => {
        activeConversationId.current = activeConversation ? activeConversation._id : null;
        setUpdated(new Date());
    }, [activeConversation]);

    // const subscribe = () => {
    //     const token = localStorage.getItem('jwt');
    //     if (!token) return;

    //     chat.subscribe(token, recieve);
    //     subscribed.current = true;
    // };

    const fetch = async () => {
        const results = await api.conversations.fetch();
        setConversations(results);
    };

    const notifyReload = async () => {
        try {
            await bridge.request('notifyReload');
            fetch();
            notifyReload();
        } catch (e) {}
    };

    const recieve = data => {
        const conversation = data.conversation;
        const message = data.message;
        console.log(data);

        if (message) {
            const updateIndex = conversations.findIndex(c => c._id === message.conversation);
            if (updateIndex < 0) return;

            const conversationUpdate = conversations[updateIndex];
            conversationUpdate.messages.push(message);
            if (message.type === 'text') conversationUpdate.unreadCount += 1;

            const update = conversations;
            update[updateIndex] = conversationUpdate;
            setConversations(update);
            setUpdated(new Date());

            if (activeConversationId.current === message.conversation) {
                read(conversationUpdate);
            }
        }
        
        if (conversation) {
            setConversations([conversation, ...conversations]);
        }
    }

    const read = async conversation => {
        console.log('read convo', conversation);
        const updateIndex = conversations.findIndex(c => c._id === conversation._id);
        if (updateIndex < 0) return;

        const conversationUpdate = conversations[updateIndex];
        conversationUpdate.unreadCount = 0;

        const update = conversations;
        update[updateIndex] = conversationUpdate;

        setConversations(update);
        setUpdated(new Date());

        await api.conversations.read(conversation);
    }

    useEffect(() => { 
        fetch();
        notifyReload();
    }, []);

    return [conversations || [], activeConversation, setActiveConversation, read, updated, totalUnreads];

};
