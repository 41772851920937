
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import formatPhone from '../../utils/formatPhone';
import api from '../../api';

const CampaignAddDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [selectedNumber, setSelectedNumber] = useSmoothState(0, 0.5);
    const [name, setName] = useSmoothState('', 0.5);
    const [saving, setSaving] = useSmoothState(false, 0.5);

    useEffect(() => {
        if (!props.dialog) {
            setAlert('initial');
            setName('initial');
            setSelectedNumber('initial');
            setSaving(false);
            return;
        }

        setAlert(props.dialog);
    }, [props.dialog]);

    const number = (item, index) => (
        <div className={conditional('number', style, { selected: selectedNumber === index }) + ' it2'} onClick={() => setSelectedNumber(index)}>
            <div className={style.text}>{formatPhone(item.phone)}</div>
            {/* <div className={style.check} /> */}
        </div>
    );

    const alertClass = conditional('CampaignAddDialog', style, { visible: props.dialog != null });
    const alertData = alert || {};

    const save = async () => {
        const number = alertData.numbers[selectedNumber];
        if (!number) return;

        setSaving(true);

        const campaign = await api.campaigns.add(name);
        const config = await api.campaigns.configureNumber(campaign._id, number._id);
        
        props.onFinish && props.onFinish(1);
        props.onSave(config);
    };

    const enabled = (alertData.numbers || []).length && name.length > 2;

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>{alertData.description}</div>
                <div className={style.info}>
                    <input value={name} className={style.input + ' ip2'} placeholder="Name your campaign..." onChange={e => setName(e.target.value)} />
                </div>
                <div className={style.numbers}>
                    {(alertData.numbers || []).map(number)}
                </div>
                <div className={style.actions}>
                    <div className={'cancel'} onClick={() => props.onFinish && props.onFinish(0)}>Cancel</div>
                    <div className={(enabled && !saving) ? 'button' : 'button disabled'} onClick={save}>{alertData.cta || 'Save'}</div>
                </div>
            </div>
        </div>
    );

};

export default CampaignAddDialog;
