
import { request } from './';

const fetch = async () => await request('/medias/fetch');

const add = async media => {
    if (!media.url) {
        const error = new Error('Cannot post media without URL');
        error.code = 'media-url-invalid';
        throw error;
    }

    const localeDate = (new Date()).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    
    const data = {
        type: media.type,
        title: media.title || (media.type === 'link' ? media.url : localeDate ),
        description: '',
        url: media.url,
        thumbnail: media.thumbnail || ''
    };

    return await request('/medias/add', data);
};

const remove = async media => {

};

// old
const postMedia = async data => await request('/medias/add', data);
const deleteMedia = async data => await request('/medias/delete', data);

export default { fetch, add, postMedia, deleteMedia };
