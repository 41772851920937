
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Collection from '../Collection';
import Empty from '../Empty';
import ConfirmDialog from '../ConfirmDialog';

import api from '../../api';

import ContactListItem from '../ContactListItem';
import ContactListEditor from '../ContactListEditor';
import ContactListAddDialog from '../ContactListAddDialog';
import HeaderButton from '../HeaderButton';

const ContactLists = props => {

    const [lists, setLists] = useState(null);
    const [selectedList, setSelectedList] = useState(null);
    const [addDialog, setAddDialog] = useState(null);
    const [confirmAlert, setConfirmAlert] = useState(null);

    useEffect(() => { fetchLists() }, []);

    const fetchLists = async () => {
        const results = await api.lists.fetch();
        // alert(JSON.stringify(results));
        setLists(results);

    };

    const addList = () => {
        setAddDialog({ description: 'Let\'s get your group going! Get started by giving your group a name.' });
    };

    const saveList = async name => {
        const result = await api.lists.add(name);
        setLists([result, ...lists]);
    };

    const deleteList = async list => {
        setConfirmAlert({ 
            description: 'Are you sure you want to delete this list? The contacts will be deleted permanently.', 
            cta: 'Delete',
            willConfirm: async () => {
                await api.lists.remove(list._id);
                const updatedLists = lists.filter(l => l._id !== list._id);
                setLists(updatedLists);
            }
        });
    };

    return (
        <div className={style.ContactLists}>
            <div className={style.content}>
                <div className={style.topBar}>
                    <div className={style.title + ' t1'}>Groups</div>
                    <div className={style.add} onClick={addList} />
                </div>
                <div className={style.lists}>
                    <div className={style.page}>
                        <Empty 
                            content={lists}
                            icon="contacts"
                            title="Inbox Empty"
                            message="Looks like there's nothing here. Add a group for your contacts to get started!"
                            cta="Add Group"
                            onSelect={addList} />
                        {lists && lists.length ? <HeaderButton icon="icon" title="Add Group" onSelect={() => addList()} /> : null}
                        <Collection
                            listLayout
                            items={lists}
                            component={ContactListItem}
                            onAction={(item, action) => action === 'delete' && deleteList(item)}
                            onSelect={list => setSelectedList(list)} />
                    </div>
                </div>
            </div>
            <ContactListAddDialog dialog={addDialog} onFinish={() => setAddDialog(null)} onSave={saveList} />
            <ConfirmDialog alert={confirmAlert} onFinish={() => setConfirmAlert(null)} />
            <ContactListEditor list={selectedList} onFinish={() => {setSelectedList(null); fetchLists();}} />
        </div>
    );

};

export default ContactLists;
