
import React, {useState} from 'react';
import style from './style.module.css';
// import api from '../../api'
// import AlertDialog from '../AlertDialog';
import formatPhone from '../../utils/formatPhone';
const CampaignItem = props => {
    // const [alert, setAlert] = useState(null);
    // const deleteButton = async () => {
    //     await api.campaigns.deleteCampaign({ 'campaign': `${props.data._id}` });
    // };
    const phone = props.data.number ? formatPhone(props.data.number.phone) : null;
    // return <div />
    return (
        <div className={style.CampaignItem} onClick={props.onSelect}>
            <div className={style.info}>
            <div className={style.titleContainer}>
                    <div className={style.title + ' it1'}>{props.data.name}</div>
                    <div className={style.company + ' it2'}>{phone}</div>
                </div>

                {/* <div className={style.title + ' it1'}>{props.data.name}</div>
                <div className={style.number + ' it2'}>{phone || 'No number assigned'}</div> */}
                {/* number on same line as title then reuse for convos */}

                <div className={style.stats}>
                    <div className={style.stat}>
                        <div className={style.icon} style={{ backgroundImage: 'url(\'/item_profile.svg\')' }} />
                        <div className={style.text + ' it2'}>{props.data.contactsCount} {props.data.contactsCount === 1 ? 'Contact' : 'Contacts'}</div>
                    </div>
                    <div className={style.stat}>
                        <div className={style.icon} style={{ backgroundImage: 'url(\'/item_lock.svg\')' }} />
                        <div className={style.text + ' it2'}>{props.data.keywords.length} {props.data.keywords.length === 1 ? 'Keyword' : 'Keywords'}</div>
                    </div>
                </div>
            </div>
            <div className={style.indicators}>
                <div className={style.delete} onClick={props.onAction('delete')} /> {/* dont show turtle green unless there are no issues */}
            </div>
        </div>
    );


    // {
    //     _id: '5f91cf02701f8e78d1b9bd59',
    //     name: 'Orlando App 2',
    //     configured: true,
    //     number: {
    //       _id: '5f9080ed54dbb55dedf41684',
    //       phone: '14077596121',
    //       name: 'Orlando Dudes'
    //     },
    //     keywords: [],
    //     contactsCount: 1,
    //     date: '2020-10-22T18:27:14.381Z'
    //   }
};

export default CampaignItem;
