
import React, { useEffect } from 'react';

import getLinkCode from '../../utils/getLinkCode';
import api from '../../api';

const PhotoEntry = props => {

    const openPage = async () => {
        const code = getLinkCode(props);
        
        try {
            await api.pages.open(code);
        } catch (e) {
            // console.log('Could not track page open');
        }
    };

    useEffect(() => { openPage() }, []);
    return <img src={props.data.url} loading="lazy" />;

};

export default PhotoEntry;
