
import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.css';
import { useConversations } from '../../utils/chatClient';
import Collection from '../Collection';
import Empty from '../Empty';

import api from '../../api';

import AnalyticsView from '../AnalyticsView';
import AnalyticsBrowserItem from '../AnalyticsBrowserItem';

const Conversations = props => {

    const [medias, setMedias] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState(null);

    const fetchData = async (keywordOnly = false) => {
        const results = await api.medias.fetch();
        setMedias(results);
    };

    useEffect(() => {
        if (!medias.length) return;
        // setSelectedMedia(medias[0]);
    }, [medias]);

    const selectMedia = item => {
        setSelectedMedia(item);

    };

    useEffect(() => { fetchData() }, []);

    return (
        <div className={style.Conversations}>
            <div className={style.content}>
                <div className={style.topBar}>
                    <div className={style.title + ' t1'}>Analytics</div>
                </div>
                <div className={style.columns}>
                <div className={style.conversations}>
                    <div className={style.page}>
                        <Empty 
                            content={medias}
                            icon="Analytics"
                            title="Analytics Empty"
                            message="Looks like there's nothing here. Come back often to check your analytics!" />
                        <Collection
                            selectedId={(selectedMedia || {})._id}
                            listLayout
                            items={medias}
                            component={AnalyticsBrowserItem}
                            onSelect={selectMedia} />
                    </div>
                </div>
                <div className={style.chatContainer}>
                    <AnalyticsView container media={selectedMedia} onFinish={() => setSelectedMedia(null)} />
                </div>
                </div>
            </div>
            <AnalyticsView media={selectedMedia} onFinish={() => setSelectedMedia(null)} />
        </div>
    );

};

export default Conversations;
