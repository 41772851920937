
import React, { useState, useEffect } from 'react';
import style from './style.module.css';

import getLinkCode from '../../utils/getLinkCode';
import api from '../../api';

import LinkEntry from './LinkEntry';
import PhotoEntry from './PhotoEntry';
import VideoEntry from './VideoEntry';

const CustomerEntry = props => {

    const [page, setPage] = useState({});

    const fetchPage = async () => {
        const code = getLinkCode(props);

        try {
            const pageFetch = await api.pages.fetch(code);
            setPage(pageFetch);
        } catch (e) {
            // console.log('Fetch page error', e);
        }
    };

    useEffect(() => { fetchPage() }, []);

    return (
        <div className={style.CustomerEntry}>
            {page.link ? <LinkEntry {...props} data={page.link} /> : null}
            {page.photo ? <PhotoEntry {...props} data={page.photo} /> : null}
            {page.video ? <VideoEntry {...props} data={page.video} /> : null}
        </div>
    );

};

export default CustomerEntry;
