
import React from 'react';
import style from './style.module.css';
import formatPhone from '../../utils/formatPhone';

const GoingItem = props => {

    return (
        <div className={style.GoingItem} onClick={props.onSelect}>
            <div className={style.info}>
                <div className={style.titleContainer}>
                <div className={style.title}>{props.data.firstName + ' ' + props.data.lastName}</div>
                <div className={props.data.selected ? (style.check + ' ' + style.selected) : style.check}></div> 
                </div>
                <div className={style.message}>{formatPhone(props.data.phone)}</div>
            </div>
        </div>
    );

};

export default GoingItem;
