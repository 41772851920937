
import { request } from './';

const sso = async (auth = null) => {
    const data = { client: 'unified', auth: auth || {} };
    const result = await request('/auth/sso', data);
    if (result.token) localStorage.setItem('jwt', result.token);

    if (auth && !result.merged) {
        const error = new Error('Incorrect username or password');
        error.code = 'auth-invalid';
        throw error;
    }

    // only for TTG, take this out for other providers
    if (auth && !result.user.completed) {
        const error = new Error('Incorrect username or password');
        error.code = 'user-invalid';
        throw error;
    }

    return result.user;
};

const update = async userInfo => {
    const user = userInfo || {};

    const required = ['email', 'password', 'firstName', 'lastName', 'phone'];
    const invalids = required.filter(field => typeof userInfo[field] !== 'string' || userInfo[field].length < 1);

    if (invalids.length) {
        const error = new Error('Looks like you forgot something');
        error.code = 'sign-up-invalid';
        error.invalids = invalids;
        throw error;
    }

    const auth = {
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        company: user.company,
        avatar: user.avatar
    };

    const data = { client: 'unified', auth: auth };
    const result = await request('/auth/update', data);
    return result.user;
};

export default { sso, update };
