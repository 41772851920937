
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import formatPhone from '../../utils/formatPhone';
import ago from '../../utils/ago';

const ContactListItem = props => {

    return (
        <div className={style.ContactListItem} onClick={props.onSelect}>
            <div className={style.info}>
                <div className={style.titleContainer}>
                    <div className={style.title + ' it1'}>{props.data.name}</div>
                </div>
                <div className={style.stats}>
                    <div className={style.stat}>
                        <div className={style.icon} style={{ backgroundImage: 'url(\'/item_profile.svg\')' }} />
                        <div className={style.text + ' it2'}>{props.data.contactsCount} {props.data.contactsCount === 1 ? 'Contact' : 'Contacts'}</div>
                    </div>
                </div>
            </div>
            <div className={style.indicators}>
                <div className={style.delete} onClick={props.onAction('delete')} />
            </div>
        </div>
    );

};

export default ContactListItem;
