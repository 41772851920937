
import { request } from './';

const fetch = async () => await request('/numbers/fetch', {});
const search = async area => await request('/numbers/search', { area });
const claim = async data => await request('/numbers/claim', data);
const migrate = async (from, to) => await request('/numbers/migrate/package', { from, to });

// show the user if they got campaigns usin the number before doin this
const archive = async number => await request('/numbers/archive', { number });

export default { fetch, search, claim, migrate, archive };
