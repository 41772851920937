
import { request } from './';

export const client = '5feb6220c54c26553c52247e';
export const shippingOption = '5fee05da8fdb6c32939a09a6';

export const packages = {
    trial: '5ff21bac48928a4dcbcb484b',
    bronze: '5fed3aa16020a71d11621d02',
    silver: '5ff21b3748928a4dcbcb4847',
    gold: '5ff21b4948928a4dcbcb4849'
};

export const extraPhone = '5fed39b66020a71d11621d00';

// the store api we are using requires this
export const trialOrganization = {
    email: 'ttgtrialorganization@geeky.media',
    phone: '14072837661',
    firstName: 'TTG',
    lastName: 'Trial Organization',
    company: 'TTG Trial Organization',
    addressLine1: '1023 Walnut St',
    city: 'Boulder',
    province: 'CO',
    postalCode: '80302',
    country: 'US'
};

export const purchaseOrganization = {
    email: 'ttgpurchaseorganization@geeky.media',
    phone: '14072837661',
    firstName: 'TTG',
    lastName: 'Purchase Organization',
    company: 'TTG Purchase Organization',
    addressLine1: '1023 Walnut St',
    city: 'Boulder',
    province: 'CO',
    postalCode: '80302',
    country: 'US'
};

export const trialPayment = {
    type: 'card',
    cc: 9560247462545996,
    cvv: 654,
    expires: '1/1/2024'
};

const fetch = async media => {
    if (!media || !media._id) {
        const error = new Error('No media provided');
        error.code = 'no-media';
        throw error;
    }

    return await request('/medias/analytics', { media: media._id });
};

// we still gotta estimate cause the store api supports shipping and shit. once the other project is done we can take this out
// /subscriptions/estimate

export default { fetch };
