
// this component is a shitshow we gotta refactor

import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import Modal from '../Modal';
import conditional from '../../utils/conditional';
import api from '../../api';
import timing from '../../utils/timing';
import phone from '../../utils/formatPhone';
import formatPhone from '../../utils/formatPhone';

import { useTabSwitcher } from '../../utils/tabSwitcher';

import SegmentTabs from '../SegmentTabs';
import subscriptionManager from '../../api/subscriptionManager';
import { packages } from '../../api/store';

// see if they can sign portfolio agreement in exchange for ongoing upgrades esp for this screen

const subscriptions = [
    {
        title: 'Trial Package', // should not show if account too old
        product: packages.trial,
        path: '/trial-membership',
        description: 'Get 100 credits to try out our app for up to 30 days',
        price: 'FREE',
        trial: true
    },
    {
        title: 'Bronze Package',
        product: packages.bronze,
        path: '/bronze-membership',
        description: 'Comes with a free phone number and 100 monthly credits',
        price: '$15/mo.',
        trial: false
    },
    {
        title: 'Silver Package',
        product: packages.silver,
        path: '/silver-membership',
        description: 'Comes with a free phone number and 200 monthly credits',
        price: '$25/mo.',
        trial: false,
    },
    {
        title: 'Gold Package',
        product: packages.gold,
        path: '/gold-membership',
        description: 'Comes with a free phone number and 500 monthly credits',
        price: '$35/mo.',
        trial: false
    }
];

const ManageSubscriptionsModal = props => {

    const [subscription, setSubscription] = useSmoothState(null, 0.5);
    const [selectedSubscription, setSelectedSubscription] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
    const [progress, setProgress] = useState(false);

    const [numbers, setNumbers] = useState([]);
    const [searchNumbers, setSearchNumbers] = useState([]);

    const [tabs, selectedTab, setSelectedTab, tabViews] = useTabSwitcher('page', style, ['Numbers', 'Packages']);

    const fetchNumbers = async () => {
        const fetchedNumbers = await api.numbers.fetch();
        setNumbers(fetchedNumbers);
    };

    const fetchSubscriptions = async () => {
        const trial = await api.subscriptionManager.checkTrial();

        if (trial) {
            setSelectedSubscription(0);
            setAvailableSubscriptions(subscriptions);
            return;
        }

        const subs = await api.subscriptionManager.configureAndFetchSubscriptions();
        const packages = subs.filter(sub => sub.path !== '/trial-membership' && sub.path !== '/extra-phone-number');
        const availableSubs = subscriptions.filter(sub => !sub.trial);
        const packageIndex = availableSubs.findIndex(sub => sub.path === ((packages[0] || {}).path || ''));
        setSelectedSubscription(packageIndex > -1 ? packageIndex : null);
        setAvailableSubscriptions(availableSubs);
    };

    useEffect(() => {
        if (!props.subscription) {
            setSubscription('initial');
            return;
        }

        setSubscription(props.subscription);

        fetchNumbers();
        fetchSubscriptions();
    }, [props.subscription]);

    const subscribe = async index => {
        if (selectedSubscription === index) return;

        const subscription = availableSubscriptions[index];
        const paymentValid = await api.subscriptionManager.checkPayment();

        const performSubscribe = async () => {
            setProgress(true);

            await api.subscriptionManager.updatePackage(subscription.product);
            await fetchSubscriptions();
            await fetchNumbers();

            setProgress(false);
            props.reloadUser();
        };

        props.requestConfirm({ description: 'Are you sure you want to change your subscription to ' + subscription.title + '? You will be billed ' + subscription.price, cta: 'Confirm' }, async result => {
            if (result !== 1) return;

            if (!subscription.trial && !paymentValid) {
                props.requestBilling({ description: 'Enter your payment information to complete this transaction.' }, async () => {
                    if (result !== 1) return;
                    await performSubscribe();
                });
            } else {
                await performSubscribe();
            }
        });
    };

    const unsubscribe = async () => {
        props.requestConfirm({ description: 'Are you sure you want to unsubscribe? This will cancel your subscription to your package.', cta: 'Confirm' }, async result => {
            if (result !== 1) return;
            setProgress(true);

            const archiveResult = await api.subscriptionManager.archivePackage();

            if (!archiveResult.success) {
                const dependencies = archiveResult.map(dep => formatPhone(dep.phone)).join(', ');
                props.requestConfirm({ description: 'You cannot cancel your subscription until you delete ' + dependencies + ' from your account.' });
                return;
            }

            await fetchSubscriptions();
            await fetchNumbers();

            setProgress(false);
        });
    };

    const deleteNumber = index => () => {
        const number = numbers[index];

        props.requestConfirm({ description: 'Are you sure? This will delete all campaigns using ' + formatPhone(number.phone) + ' and subscribers will stop recieving your messages.', cta: 'Confirm' }, async result => {
            if (result !== 1) return;
            setProgress(true);

            await api.subscriptionManager.deleteNumber(number._id);
            await fetchSubscriptions();
            await fetchNumbers();

            setProgress(false);
            props.reloadNumbers();
        });
    };

    const updateSearch = async area => {
        if (!area.length) setSearchNumbers([]);
        if (area.length < 3) return;

        const result = await api.subscriptionManager.searchNumbers(area);
        setSearchNumbers(result.numbers.map(number => ({ search: true, name: null, phone: number })));
    };

    const claimNumber = async phone => {

        // must have subscription already otherwise do payment dialog and shit

        const subs = await api.subscriptionManager.fetchSubscriptions();
        const packages = subs.filter(sub => sub.path !== '/extra-phone-number');
        const trials = subs.filter(sub => sub.path === '/trial-membership');

        if (!packages.length) {
            props.requestConfirm({ description: 'You currently don\'t have an active membership. Please subscribe to a package and try again.' });
            return;
        }

        const performClaim = async () => {
            setProgress(true);

            await api.subscriptionManager.claimNumber(phone, '');
            await fetchSubscriptions();
            await fetchNumbers();

            setProgress(false);
        };

        const paymentValid = await api.subscriptionManager.checkPayment();
        const cost = !numbers.length ? 'This number is included with your membership at no extra cost.' : 'You will be billed $10/mo. for this number.';

        props.requestConfirm({ description: 'Claim ' + formatPhone(phone) + '? ' + cost, cta: 'Confirm' }, async result => {
            if (result !== 1) return;

            if (paymentValid || (trials.length && !numbers.length)) {
                await performClaim();
            } else {
                props.requestBilling({ description: 'Enter your payment information to complete this transaction.' }, async () => {
                    if (result !== 1) return;
                    await performClaim();
                });
            }
        });
    };

    const numberItem = (number, index) => {
        const packageClass = conditional('number', style, {  });
        const firstNumber = index === 0 ? 'Included' : '$10/mo.';
        const additional = !numbers.length ? 'Included' : '$10/mo.'

        return (
            <div className={packageClass} onClick={() => number.search && claimNumber(number.phone)}>
                <div className={style.info}>
                    <div className={style.title + ' it1'}>{number.search ? formatPhone(number.phone) : (number.name || formatPhone(number.phone))}</div>
                    <div className={style.description + ' it2'}>{number.search ? 'Purchase' : (number.name ? formatPhone(number.phone) : 'Claimed') }</div>
                </div>
                <div className={style.options}>
                    <div className={style.arrow + ' ita2'}>{!number.search ? firstNumber : additional}</div>
                    {!number.search ? <div className={style.delete + ' ita2'} onClick={deleteNumber(index)} /> : null}
                </div>
            </div>
        );
    };

    const subscriptionItem = (subscription, index) => {
        const packageClass = conditional('package', style, { selected: selectedSubscription === index });

        return (
            <div className={packageClass} onClick={() => subscribe(index)}>
                <div className={style.info}>
                    <div className={style.title + ' it1'}>{subscription.title}</div>
                    <div className={style.description + ' it2'}>{subscription.description}</div>
                </div>
                <div className={style.arrow + ' ita2'}>{subscription.price}</div>
            </div>
        );
    };

    const subscriptionInfo = subscription || {};

    return (
        <Modal noIndent navigationTitle="Manage Subscriptions" {...props} visible={props.subscription} aboveTabs>
            <div className={style.container}>
                <SegmentTabs selectedTab={selectedTab} tabs={tabs} onSelect={setSelectedTab} />

                <div className={tabViews[0]}>
                    <div className={style.content}>
                        {numbers.map(numberItem)}
                        <div className={style.search}>
                            <input className={style.input + ' ip2'} onChange={timing.debounce(updateSearch)} placeholder="Claim by area code..." />
                        </div>
                        {searchNumbers.map(numberItem)}
                    </div>
                </div>

                <div className={tabViews[1]}>
                    <div className={style.content}>
                        {availableSubscriptions.map(subscriptionItem)}
                        <div className={style.cancel} onClick={unsubscribe}>
                            <div className={selectedSubscription === null ? 'cta disabled' : 'cta'}>Cancel Subscription</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={conditional('progress', style, { visible: progress })}>
                <div className={style.progressMessage}>Upgrading your package...</div>
            </div>
        </Modal>
    );

};

export default ManageSubscriptionsModal;
