
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import api from '../../api';

const BillingDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [formData, setFormData] = useSmoothState({ cc: '', exp: '', cvv: '', zip: '' }, 0.5);
    const [validated, setValidated] = useSmoothState(false, 0.5);
    const [progress, setProgress] = useSmoothState(false, 0.5);

    useEffect(() => {
        if (!props.alert) {
            setAlert('initial');
            setFormData('initial');
            setValidated('initial');
            setProgress('initial');

            return;
        }

        setAlert(props.alert);
    }, [props.alert]);

    useEffect(() => { revalidate() }, [formData]);

    const alertClass = conditional('BillingDialog', style, { visible: props.alert != null });

    const finish = () => {
        setAlert(null);
        props.onDone();
    };

    const confirm = async () => {
        // 1. disable button
        // 2. verify payment with 3rd party api
        // 3. add payment to our api

        const components = formData.exp.split('/');
        if (components.length !== 2) return;

        const dateString = components[0] + '/01/' + components[1];
        if (isNaN(Date.parse(dateString))) return;

        const result = new Date(dateString);
        const validYears = [2021, 2022, 2023, 2024, 2025];
        if (!validYears.includes(result.getFullYear())) return;

        setProgress(true);

        const payment = {
            cc: formData.cc,
            cvv: formData.cvv,
            exp: dateString,
            zip: formData.zip
        };
        
        await api.subscriptionManager.updatePayment(payment);
        props.onFinish({ exp: payment.exp, zip: payment.zip });

        return true;
    };

    const changeField = field => e => {
        let value = e.target.value;
        let rawValue = value.split(' ').join('').trim();

        if (field === 'cc' && (isNaN(rawValue) || rawValue.length > 16)) return;
        if (field === 'cvv' && rawValue.length > 3) return;
        if (field === 'exp' && rawValue.length > 5) return;
        if (field === 'zip' && rawValue.length > 5) return;

        if (field === 'exp' && rawValue.length > 2 && !rawValue.includes('/')) {
            value = [rawValue.slice(0, 2), '/', rawValue.slice(2)].join('');
        };

        if (field === 'cc') {
            const matchs = rawValue.match(/.{1,4}/g) || [];
            value = matchs.join(' ');
        }

        setFormData({ ...formData, [field]: value });
    };

    const rawValue = field => (formData[field] || '').split(' ').join('').trim();

    const formatDate = date => {
        const components = date.split('/');
        if (components.length !== 2) return null;

        const dateString = components[0] + '/01/' + components[1];
        if (isNaN(Date.parse(dateString))) return null;

        const result = new Date(dateString);
        const validYears = [2021, 2022, 2023, 2024, 2025];
        if (!validYears.includes(result.getFullYear())) return null;

        return result.toString();
    };

    const revalidate = () => {
        if (isNaN(parseInt(rawValue('cc'))) || rawValue('cc').length !== 16) return setValidated(false);
        if (isNaN(parseInt(rawValue('cvv'))) || rawValue('cvv').length < 3) return setValidated(false);

        if (!formatDate(rawValue('exp'))) return setValidated(false);
        if (isNaN(parseInt(rawValue('zip'))) || rawValue('zip').length < 5) return setValidated(false);
        setValidated(true);
    };

    const alertData = alert || {};

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>{alertData.description}</div>
                <div className={style.card}>
                    <input value={formData.cc} className={style.input + ' ip2'} placeholder="1234 1234 1234 1234" pattern="[0-9.]+" onChange={changeField('cc')} />
                </div>
                <div className={style.info}>
                    <input value={formData.exp} className={style.inputLeft + ' ip2'} placeholder="MM/YY" onChange={changeField('exp')} />
                    <input value={formData.cvv} className={style.inputMiddle + ' ip2'} placeholder="CVC" type="text" onChange={changeField('cvv')} />
                    <input value={formData.zip} className={style.inputRight + ' ip2'} placeholder="Zip Code" type="text" onChange={changeField('zip')} />
                </div>
                <div className={style.actions}>
                    <div className={style.cancel + ' cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div>
                    <div className={(validated && !progress) ? 'button' : 'button disabled'} onClick={confirm}>{alertData.cta || 'Save'}</div>
                </div>
            </div>
        </div>
    );

};

export default BillingDialog;
