
import React from 'react';
import ReactDOM from 'react-dom';
import authStorage from './utils/authStorage';
import * as serviceWorker from './serviceWorker';
import App from './components/App';

ReactDOM.render(<App authData={authStorage.get()} />, document.getElementById('root'));
// serviceWorker.register();
serviceWorker.unregister();
