
// copped from Andres with slight refactoring

const format = async (image, opts = null) => {
    const options = opts || {};
    const imageFromFile = await fileImage(image);

    const size = options.width || options.height || 512;
    const scale = size / (options.height ? imageFromFile.height : imageFromFile.width);

    return await scaleImage(imageFromFile, scale);
};

const fileImage = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject();

    reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onerror = () => reject();
        image.onload = () => resolve(image);
    };

    reader.readAsDataURL(file);
});

const scaleImage = (image, scaleFactor) => new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width * scaleFactor;
    canvas.height = image.height * scaleFactor;
    canvas.style.display = 'none';
    document.body.appendChild(canvas);

    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7);
});

export default format;
