
import React from 'react';
import style from './style.module.css';
import { Link, withRouter } from 'react-router-dom';
import conditional from '../../utils/conditional';

const links = [
    { path: '/', label: 'Home', icon: 'home' },
    { path: '/broadcasts', label: 'Event Blast', icon: 'send' },
    { path: '/campaigns', label: 'Campaign', icon: 'campaign' },
    { path: '/keywords', label: 'Keyword', icon: 'lock_open' },
    // { path: '/drip', label: 'Drip Campaign', icon: 'campaign' },
    // { path: '/polls', label: 'Poll/Survey', icon: 'campaign' },
    { path: '/media', label: 'Media', icon: 'insert_link' },
    { path: '/conversations', label: 'Inbox', icon: 'inbox' },
    { path: '/analyze', label: 'Analyze', icon: 'analytics' },
    { path: '/qr-codes', label: 'QR Codes', icon: 'qr_code' },
    { path: '/settings', label: 'Settings', icon: 'settings' },
    { path: '/sign-out', label: 'Sign Out', icon: 'login' },
];

const NavLink = props => {

    const active = props.route === props.link.path;
    const bottomed = ['/sign-in', '/sign-out'].includes(props.link.path);
    const selectedClass = conditional('navLink', style, { active, bottomed });

    return (
        <Link to={props.link.path}>
            <div className={selectedClass}>
                <div className={style.icon}><span className="material-icons">{props.link.icon}</span></div>
                <div className={style.label}>{props.link.label}</div>
            </div>
        </Link>
    );

};

const Sidebar = props => {

    const route = props.location.pathname;

    const sidebarClass = conditional('Sidebar', style, props);

    return (
        <div className={sidebarClass}>
            <div className={style.branding}>
                TTG
            </div>
            {links.map(link => <NavLink key={link.path} link={link} route={route} />)}
        </div>
    );

};

export default withRouter(Sidebar);
