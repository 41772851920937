
import React from 'react';
import style from './style.module.css';

const StatItem = props => {

    return (
        <div className={style.StatItem} onClick={props.onSelect}>
            <div className={style.icon} style={{ backgroundImage: 'url("/stat_' + props.data.icon + '.svg")' }} />
            <div className={style.info}>
                <div className={style.title + ' it1'}>{props.data.title}</div>
                <div className={style.category + ' it2'}>{props.data.category}</div>
            </div>
        </div>
    );

};

export default StatItem;
