
import { request } from './';

const fetch = async () => {
    const lists = await request('/lists/fetch', {});
    return lists.filter(list => list.name !== 'Main Contacts');
};

const add = async name => await request('/lists/add', { name });
const remove = async list => await request('/lists/delete', { list });

const fetchContacts = async list => await request('/lists/contacts/fetch', { list });
const addContacts = async (list, contacts) => await request('/lists/contacts/add', { list, contacts: { data: contacts } });
const removeContacts = async (list, contacts) => await request('/lists/contacts/delete', { list, contacts });

const fetchMainContacts = async () => await request('/lists/contacts/fetch', { list: 'main' });

export default { fetch, add, remove, fetchContacts, addContacts, removeContacts, fetchMainContacts };
