
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const Collection = props => {

    const Component = props.component;

    const components = (props.items || []).map((item, index) => (
        <Component 
            key={index} 
            data={{ ...item, index, selected: props.selectedId ? item._id === props.selectedId : item.selected }}
            itemType={props.itemType || 'default'}
            hideDelete={props.hideDelete}
            refresh={() => props.refresh && props.refresh()}
            onSelect={() => props.onSelect && props.onSelect(item)}
            onAction={action => e => {
                e.stopPropagation();
                props.onAction && props.onAction(item, action);
            }} />
    ));

    if (props.footerComponent) {
        const Footer = props.footerComponent;
        const data = props.data || {};

        components.push((
            <Footer 
                key={'footer'} 
                data={data}
                itemType={props.itemType || 'default'}
                refresh={() => props.refresh && props.refresh()}
                onSelect={() => props.onSelectFooter && props.onSelectFooter()} />
        ));
    }

    const collectionClass = conditional('Collection', style, props);
    return <div className={collectionClass}>{components}</div>;

};

export default Collection;
