
import { request } from './';

const fetch = async code => {
    if (!code) {
        const error = new Error('No code provided');
        error.code = 'no-code';
        throw error;
    }

    return await request('/pages/fetch', { code });
};

const open = async code => {
    if (!code) {
        const error = new Error('No code provided');
        error.code = 'no-code';
        throw error;
    }

    const result = await request('/pages/open', { code });
    return result.success ? true : false;
};

const watch = async (code, seconds) => {
    if (!code) {
        const error = new Error('No code provided');
        error.code = 'no-code';
        throw error;
    }

    const result = await request('/pages/watch', { code, watchData: { seconds } });
    return result.success ? true : false;
};

export default { fetch, open, watch };
