
import React, { useEffect } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import bridge from 'bridge-request';
import api from '../../api';

import Modal from '../Modal';
// import Button from '../Button';
import Collection from '../Collection';

import PhoneContactItem from '../PhoneContactItem';
import SendButton from '../SendButton';

const Footer = props => <div style={{height: '66px'}} />

const ShareText = props => {

    const [contacts, setContacts] = useSmoothState(null, 0.5);
    const [submitting, setSubmitting] = useSmoothState(false);
    const [sent, setSent] = useSmoothState(false);
    const [search, setSearch] = useSmoothState('', 0.5);

    useEffect(() => {
        if (!props.visible) {
            setContacts('initial');
            setSubmitting('initial');
            setSent('initial');
            setSearch('initial');
            return;
        }

        fetchContacts();
    }, [props.visible]);

    const fetchContacts = async () => {
        try {
            const contactsFetch = await bridge.request('fetchContacts');
            const contacts = contactsFetch.results
                .filter(contact => (contact.phone || '').length > 10 && ((contact.firstName || '') + (contact.lastName || '')).length)
                .map(contact => ({
                    firstName: contact.firstName || '',
                    lastName: contact.lastName || '',
                    phone: contact.phone.replace(/\D/g, '').length < 11 ? '1' + contact.phone.replace(/\D/g, '') : contact.phone.replace(/\D/g, ''),
                    selected: false
                }))
                .sort((a, b) => {
                    const sortName = c => c.firstName.length > 0 ? c.firstName : c.lastName;
                    return sortName(a).toLowerCase().localeCompare(sortName(b).toLowerCase())
                });
            setContacts(contacts);
        } catch (e) {
            console.log(e);
            // show alert
        }
    };

    const send = async () => {
        setSubmitting(true);

        const contactPhones = (contacts || []).filter(contact => contact.selected).map(contact => ({
            phone: contact.phone.length > 10 ? contact.phone : '1' + contact.phone,
            firstName: contact.firstName,
            lastName: contact.lastName,
            company: contact.company || ''
        }));
        await props.onSave(contactPhones)
        // await api.events.invite(props.event._id, contactPhones);

        setSubmitting(false);
        props.onFinish();
    };

    const selectContact = selected => {
        setContacts(contacts.map(contact => ({ ...contact, selected: contact.phone === selected.phone ? !contact.selected : contact.selected })));
    };

    const selected = (contacts || []).filter(contact => contact.selected);
    console.log(contacts)

    return (
        <Modal navigationTitle="Upload Contacts" {...props} visible={props.visible} aboveTabs>
            <div className={style.contacts}>
            <input className={style.input} value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} />
            <Collection
                listLayout
                items={(contacts ? contacts : []).filter(contact => search.length ? ((contact.firstName || '') + (contact.lastName || '')).toLowerCase().includes(search.toLowerCase()) : true)}
                component={PhoneContactItem}
                footerComponent={Footer}
                onSelect={selectContact} />
            </div>
            <div className={style.send}>
                <SendButton disabled={sent || submitting || !selected.length} sending={submitting} onSelect={send} />
            </div>
        </Modal>
    );

};

export default ShareText;
