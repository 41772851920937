
import { request } from './';

const scan = async code => await request('/tags/scan', { code });
const subscribe = async (tag, contact) => await request('/tags/subscribe', { tag, contact });
const fetch = async () => await request('/tags/fetch', {});
const add = async (campaign, type) => await request('/tags/add', { campaign, type });
const remove = async tag => await request('/tags/archive', { tag });

export default { scan, subscribe, fetch, add, remove };
