
import React, { useRef, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import Modal from '../Modal';
import Collection from '../Collection';
import MediaAnalyticsItem from '../MediaAnalyticsItem';
import api from '../../api';

import ago from '../../utils/ago';

const ConversationChat = props => {

    const text = useRef();
    const content = useRef();
    
    const desktopChat = useRef();
    const scrollBottom = useRef();

    const [messageText, setMessageText] = useSmoothState('', 0.5);

    const mediaComponents = {
        link: media => (
            <div className={style.link}>
                <div className={style.title + ' h2'}>{media.title}</div>
                <div className={style.url + ' it2'}>{media.url}</div>
                <div className={style.button + ' cta'} onClick={() => window.open(media.url, '_blank')}>Preview Link</div>
            </div>
        ),
        photo: media => <img className={style.photo} src={media.url} loading="lazy" />,
        video: media => <video className={style.video} src={media.url} controls />
    };

    const [analytics, setAnalytics] = useSmoothState(null, 0.5);

    const mediaData = props.media || {};
    const component = mediaComponents[mediaData.type];

    useEffect(() => { 
        if (!props.media) return;
        fetchAnalytics();
    }, [props.media]);

    const fetchAnalytics = async () => {
        const results = await api.analytics.fetch(props.media);
        setAnalytics(results);
    };
    
    if (props.container) {
        return (
            <div ref={desktopChat} className={style.desktopOnly} navigationTitle={'Test'} {...props} visible={props.conversation} scroll={props.update}>
                <div className={style.content} ref={content}>
                <div className={style.preview}>
                    {component ? component(mediaData) : null}
                </div>
                <div className={style.analytics}>
                    <div className={style.analyticsLabel + ' it2'}>Analytics</div>
                    <Collection
                        listLayout
                        items={analytics}
                        component={MediaAnalyticsItem}
                        onSelect={() => {}} />
                </div>
                </div>
            </div>
        );
    }

    return (
        <Modal mobileOnly scrolls navigationTitle={'Media'} {...props} visible={props.media} scroll={props.update}>
            <div className={style.content} ref={content}>
                <div className={style.preview}>
                    {component ? component(mediaData) : null}
                </div>
                <div className={style.analytics}>
                    <div className={style.analyticsLabel + ' it2'}>Analytics</div>
                    <Collection
                        listLayout
                        items={analytics}
                        component={MediaAnalyticsItem}
                        onSelect={() => {}} />
                </div>
            </div>
        </Modal>
    );

};

export default ConversationChat;
