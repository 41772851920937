
const get = () => {
    try {
        const storedAuth = localStorage.getItem('authData');
        if (!storedAuth) return null;

        const authData = JSON.parse(storedAuth);
        return authData;
    } catch (e) {
        // console.log('Error getting stored auth data');
        return null;
    }
};

const save = result => {
    const auth = {
        user: result.user,
        token: result.token
    };

    try {
        const authData = JSON.stringify(auth);
        localStorage.setItem('authData', authData);
    } catch (e) {
        // console.log('Error saving stored auth data');
    }
};

export default { get, save };
