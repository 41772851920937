
import React, { useRef, useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import Modal from '../Modal';

import formatPhone from '../../utils/formatPhone';
import ago from '../../utils/ago';

const ConversationChat = props => {

    const text = useRef();
    const content = useRef();
    
    const desktopChat = useRef();
    const scrollBottom = useRef();

    const [conversation, setConversation] = useSmoothState(null, 0.5);
    const [messageText, setMessageText] = useSmoothState('', 0.5);
    const [sending, setSending] = useSmoothState(false, 0.5);

    useEffect(() => {
        if (!props.conversation) {
            setConversation('initial');
            setMessageText('initial');
            text.current.textContent = '';
            return;
        }

        setConversation(props.conversation);
    }, [props.conversation]);

    useEffect(() => {
        if (!props.update) return;
        if (!desktopChat.current) return;
        if (!scrollBottom.current) return;
        scrollBottom.current.scrollIntoView({ behavior: 'smooth' });
    }, [props.update]);

    const changeText = () => {
        const update = text.current.textContent;
        setMessageText(update);
    };

    const send = async () => {
        if (sending) return;
        setSending(true);

        const result = await props.onSend(messageText);
        
        if (!result) {
            setSending(false);
            return;
        }

        text.current.textContent = '';
        setMessageText('');
        setSending(false);
    };

    const chatItem = (message, index) => {
        if (message.label) return <div className={conditional('time', style, { sent: message.sent }) + ' it2'}>{message.label}</div>;

        const messageClass = conditional('message', style, { sent: message.sent });

        return (
            <div className={messageClass}>
                <div className={style.bubble + ' it2'}>
                    {message.message}
                </div>
            </div>
        );
    };

    const conversationData = conversation || {};

    const contact = conversationData.contact || {};
    const messages = conversationData.messages || [];

    const phone = contact.number ? formatPhone(contact.number.phone) : null;
    const name = contact.firstName || contact.lastName ? contact.firstName + ' ' + contact.lastName : phone;

    const chats = [];
    let lastDate = null;

    for (const message of messages) {
        const sendDate = new Date(message.sendDate);

        if (lastDate && sendDate.getTime() - lastDate.getTime() < 3600000) {
            chats.push({ message: message.message, sent: message.type === 'web' });
        } else {
            const agoDate = ago(message.sendDate);
            chats.push({ label: agoDate, sent: message.type === 'web' });
            chats.push({ message: message.message, sent: message.type === 'web' });
        }

        lastDate = sendDate;
    }

    if (props.container) {
        return (
            <div ref={desktopChat} className={style.desktopOnly} navigationTitle={name} {...props} visible={props.conversation} scroll={props.update}>
                <div className={style.content} ref={content}>
                    <div className={style.messages}>
                        {chats.map(chatItem)}
                        <div ref={scrollBottom} />
                    </div>
                </div>
                <div className={style.textInputContainer}>
                    <div className={style.textInput}>
                        <div className={conditional('placeholder', style, { visible: messageText.length < 1 })}>Text message...</div>
                        <div className={style.input} ref={text} contentEditable="true" onInput={changeText}></div>
                    </div>
                    <div className={style.send} onClick={send} />
                </div>
            </div>
        );
    }

    return (
        <Modal mobileOnly scrolls navigationTitle={name} {...props} visible={props.conversation} scroll={props.update}>
            <div className={style.content} ref={content}>
                <div className={style.messages}>
                    {chats.map(chatItem)}
                </div>
            </div>
            <div className={style.textInputContainer}>
                <div className={style.textInput}>
                    <div className={conditional('placeholder', style, { visible: messageText.length < 1 })}>Text message...</div>
                    <div className={style.input} ref={text} contentEditable="true" onInput={changeText}></div>
                </div>
                <div className={style.send} onClick={send} />
            </div>
        </Modal>
    );

};

export default ConversationChat;
