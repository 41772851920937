
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';
import formatPhone from '../../utils/formatPhone';
import api from '../../api';

const ContactAddDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [contact, setContact] = useSmoothState({ firstName: '', lastName: '', phone: '', company: '' }, 0.5);
    const [saving, setSaving] = useSmoothState(false, 0.5);
    const [selectedList, setSelectedList] = useSmoothState(0, 0.5);

    useEffect(() => {
        if (!props.dialog) {
            setAlert('initial');
            setContact('initial');
            setSaving(false);
            setSelectedList(0);
            return;
        }

        setAlert(props.dialog);
    }, [props.dialog]);

    const rawPhone = value => value.replace(/\D/g, '');
    const contactData = () => ({ ...contact, phone: rawPhone(contact.phone) });
    const listData = () => ({ list: alertData.lists[selectedList] });

    const alertClass = conditional('ContactAddDialog', style, { visible: props.dialog != null });
    const alertData = alert || {};

    const updateContact = field => e => {
        let value = e.target.value;

        if (field === 'phone') {
            if (value === '1') value = '+1';
            if ((value.substr(0, 2) !== '+1' && value.substr(0, 1) !== '1') && value.length === 2) value = '+1' + value;
            if (isNaN(parseInt(value)) && value.length && value !== '+') return;
            
            const phone = rawPhone(value).length > 10 ? formatPhone(rawPhone(value)) : value;
            setContact({ ...contact, phone });
            return;
        }

        setContact({ ...contact, [field]: value });
    };

    const list = (item, index) => (
        <div className={conditional('list', style, { selected: selectedList === index }) + ' it2'} onClick={() => setSelectedList(index)}>
            <div className={style.text}>{item.name}</div>
            <div className={style.desc}>{item.contactsCount} {item.contactsCount === 1 ? 'Contact' : 'Contacts'}</div>
            {/* <div className={style.check} /> */}
        </div>
    );

    const isList = alertData.type === 'list' ? true : false;

    const infoClass = conditional('info', style, { hidden: isList });
    const listClass = conditional('lists', style, { hidden: !isList });

    const enabled = (contact.phone.length > 10 && contact.firstName.length > 0 && contact.lastName.length > 0) || isList;

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>{isList ? 'Select a group to import. Every contact in the group will start recieving messages!' : 'Enter the contact info here so they will start recieving your messages!'}</div>
                <div className={infoClass}>
                    <input value={contact.firstName} className={style.inputLeft + ' ip2'} placeholder="First Name" onChange={updateContact('firstName')} />
                    <input value={contact.lastName} className={style.inputRight + ' ip2'} placeholder="Last Name" onChange={updateContact('lastName')} />
                </div>
                <div className={infoClass}>
                    <input value={contact.phone} className={style.inputLeft + ' ip2'} placeholder="Phone" onChange={updateContact('phone')} />
                    <input value={contact.company} className={style.inputRight + ' ip2'} placeholder="Company" onChange={updateContact('company')} />
                </div>
                <div className={listClass}>
                    {(alertData.lists || []).map(list)}
                </div>
                <div className={style.actions}>
                    <div className={'cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div>
                    <div className={(enabled && !saving) ? 'button' : 'button disabled'} onClick={() => props.onFinish && props.onFinish(isList ? listData() : contactData())}>{alertData.cta || 'Save'}</div>
                </div>
            </div>
        </div>
    );

};

export default ContactAddDialog;
