
import React, { useRef, useState, useEffect } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import Modal from '../Modal';
import SegmentTabs from '../SegmentTabs';
import MessageAdd from '../CampaignsConfigure';
import ContactImporter from '../ContactImporter';
import ConfirmDialog from '../ConfirmDialog';
import AlertDialog from '../AlertDialog';
import api from '../../api';
import Empty from '../Empty';
import Collection from '../Collection';
import ContactManagerItem from '../ContactManagerItem';
import KeywordItem from '../KeywordItem';
import KeywordAddItem from '../KeywordAddItem';
import ContactAddDialog from '../ContactAddDialog';
import KeywordAddDialog from '../KeywordAddDialog';
import MediaAddDialog from '../MediaAddDialog';
import OptionMenu from '../OptionMenu';
import bridge from 'bridge-request';
import fileData from '../../utils/fileData';
import contactsCSV from '../../utils/contactsCSV';
import QR from './QR';

import PhoneContacts from '../PhoneContacts';

const CampaignEditor = props => {

    const [campaign, setCampaign] = useSmoothState(null, 0.5);
    const [messages, setMessages] = useSmoothState(null, 0.5);
    const [contacts, setContacts] = useSmoothState(null, 0.5);
    const [keywords, setKeywords] = useSmoothState(null, 0.5);
    const [addMessage, setAddMessage] = useSmoothState(null, 0.5);
    const [addContact, setAddContact] = useSmoothState(null, 0.5);
    const [addKeyword, setAddKeyword] = useSmoothState(null, 0.5);
    const [importContacts, setImportContacts] = useSmoothState(null, 0.5);
    const [addPhoneContacts, setAddPhoneContacts] = useSmoothState(null, 0.5);
    const [options, setOptions] = useSmoothState(null, 0.5);
    const [confirmAlert, setConfirmAlert] = useSmoothState(null, 0.5);
    const [noContactsAlert, setNoContactsAlert] = useSmoothState(null, 0.5);
    const [mediaLink, setMediaLink] = useSmoothState(null, 0.5);
    const [linkMedia, setLinkMedia] = useSmoothState(null, 0.5);
    const [qrCode, setQRCode] = useSmoothState(null, 0.5);
    const [tabs, selectedTab, setSelectedTab, tabViews] = useTabSwitcher('page', style, ['Messages', 'Contacts', 'Keywords', 'QR']);

    const [hideFirstTab, setHideFirstTab] = useState(false);
    
    const container = useRef(null);
    const selectedMessage = useRef(null);
    const selectedContact = useRef(null);

    const adjustSize = () => {
        console.log(window.innerWidth);

        if (window.innerWidth >= 900) {
            setHideFirstTab(true);
            if (selectedTab === 0) setSelectedTab(1);
        } else {
            setHideFirstTab(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', () => {
            adjustSize();
        });

        adjustSize();
    }, []);

    let fetcher = null;

    useEffect(() => {
        if (!props.campaign) {
            setCampaign('initial');
            setMessages('initial');
            setContacts('initial');
            setKeywords('initial');
            setAddMessage('initial');
            setAddContact('initial');
            setAddKeyword('initial');
            setAddPhoneContacts('initial');
            setImportContacts('initial');
            setOptions('initial');
            setMediaLink('initial');
            setLinkMedia('initial');
            setConfirmAlert('initial');
            setQRCode('initial');
            clearInterval(fetcher);
            return;
        }

        setCampaign(props.campaign);
    }, [props.campaign]);

    useEffect(() => { 
        fetchMessages();
        fetchContacts();
        fetchKeywords();
        fetchQR();
        // should have some kind of tip banner that says if you got no contacts yet
    }, [campaign]);

    const fetchMessages = async () => {
        if (!campaign) return;
        
        const fetchedMessages = await api.campaigns.fetchMessages(campaign._id);
        setMessages(fetchedMessages);
    };

    const fetchContacts = async () => {
        if (!campaign) return;

        const fetchedContacts = await api.campaigns.fetchContacts(campaign._id);
        setContacts(fetchedContacts);
    };

    const reloadContacts = async () => {
        if (!campaign) return;

        const fetchedContacts = await api.campaigns.fetchContacts(campaign._id);
        if (JSON.stringify(contacts) !== JSON.stringify(fetchedContacts)) setContacts(fetchedContacts);
    };

    const fetchKeywords = async () => {
        if (!campaign) return;
        
        const keywordItems = campaign.keywords.map(keyword => ({ keyword, phone: (campaign.number || {}).phone }));
        setKeywords(keywordItems);
    };

    const deleteMessage = async message => {
        setConfirmAlert({ 
            description: 'Are you sure you want to delete this message? None of your subscribers will recieve this message going forward.', 
            cta: 'Delete',
            willConfirm: async () => {
                await api.campaigns.removeMessage(campaign._id, message);
                const updatedMessages = messages.filter(m => m._id !== message);
                setMessages(updatedMessages);
            }
        });
    };

    const showNoContactsAlert = () => {
        setNoContactsAlert({ 
            description: 'Heads up! Nobody will get your messages until you add contacts.', 
            cta: 'Got It!',
            willConfirm: async () => {

            }
        });
    }

    const deleteContact = async contact => {
        selectedContact.current = contact;
        setConfirmAlert({ 
            description: 'Are you sure you want to delete this contact? They will not recieve your messages from this campaign going forward.', 
            cta: 'Delete',
            willConfirm: async () => {
                await api.campaigns.removeContacts(campaign._id, [contact._id]);
                const updatedContacts = contacts.filter(c => c._id !== contact._id);
                setContacts(updatedContacts);
            }
        });
    };

    const deleteKeyword = async keyword => {
        setConfirmAlert({ 
            description: 'Are you sure you want to delete this keyword? If someone tries using it, they won\'t be able to opt-in.', 
            cta: 'Delete',
            willConfirm: async () => {
                await api.campaigns.removeKeyword(campaign._id, keyword);
                const updatedKeywords = keywords.filter(kw => kw.keyword !== keyword);
                setKeywords(updatedKeywords);
            }
        });
    };

    const finishConfirmPrompt = async index => {
        setConfirmAlert(null);
    };

    const finishImport = () => setImportContacts(null);

    const messageItem = (message, index) => {
        if (message.label) return <div className={style.time + ' it2'}>{message.label}</div>;
        
        const media = message.message.media;

        return (
            <div className={style.container}>
                <div className={style.message}>
                    {media ? <div className={style.mediaBubble + ' it2'}>
                        <div className={style.mediaIcon} style={{ backgroundImage: 'url(\'' + 'chat_attach.svg' + '\')' }} />
                        <div className={style.mediaTitle + ' it2'}>{media.title}</div>
                    </div> : null}
                    
                    <div className={style.bubble + ' it2'}>
                        {message.message.message}
                    </div>
                </div>
                <div className={style.icon} onClick={() => deleteMessage(message.message._id)} />
            </div>
        );
    };

    const saveMessage = message => {
        // TODO: insert to improve performance instead of reloading
        fetchMessages();
    };

    const finishContactPrompt = async contact => {

        setAddContact(null);
        if (!contact) return;

        const result = await api.campaigns.addContacts(campaign._id, contact.list ? contact.list._id : [contact]);
        const updatedContacts = [...contacts, ...result.added].sort((a, b) => a.lastName.localeCompare(b.lastName));

        setContacts(updatedContacts);
        setAddContact(null);

        fetchContacts();
    };

    const uploadContacts = async results => {
        setAddContact(null);
        setAddPhoneContacts(null);
        if (!results || !results.length) return;

        const result = await api.campaigns.addContacts(campaign._id, results.map(r => ({ ...r, selected: 'true' })));
        const updatedContacts = [...contacts, ...result.added].sort((a, b) => a.lastName.localeCompare(b.lastName));
        setContacts(updatedContacts);
    };

    const finishKeywordPrompt = async keyword => {
        setAddKeyword(null);
        if (!keyword) return;

        await api.campaigns.addKeyword(campaign._id, keyword);
        const updatedKeywords = [...keywords, { keyword, phone: (campaign.number || {}).phone }].sort((a, b) => a.keyword.localeCompare(b.keyword));

        setKeywords(updatedKeywords);
        setAddKeyword(null);
    };

    const campaignInfo = campaign || {};

    const messageItems = [];
    let lastMessageItemSort = null;

    for (const message of (messages || []).sort((a, b) => (a.sequence ? 0 : 1) - (b.sequence ? 0 : 1))) {
        if (message.sort !== lastMessageItemSort) {
            lastMessageItemSort = message.sort;

            if (message.sequence) {
                const days = message.sequence.sendAfter / 86400;

                if (days < 1) {
                    messageItems.push({ label: 'Immediately send after subscribing' });
                } else {
                    messageItems.push({ label: days + ' days after subscribing' })
                }
            }

            if (message.schedule) {
                const date = new Date(message.schedule.date);
                const repeats = ['weekly', 'monthly', 'yearly'].includes(message.schedule.repeatType) ? ', repeats ' + message.schedule.repeatType : '';
                messageItems.push({ label: date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + repeats });
            }

            messageItems.push({ message });
        } else {
            messageItems.push({ message });
        }
    }

    const addOptions = [
        {
            title: 'Import From Group',
            onSelect: async () => {
                const lists = await api.lists.fetch();
                setAddContact({ ...campaignInfo, type: 'list', lists });
            }
        },
        {
            title: 'Add Manually',
            onSelect: () => setAddContact({ ...campaignInfo, type: 'manual' })
        }
    ];

    const addFromGroup = async () => {
        const response = await api.lists.fetch();
        const lists = response.filter(list => list.name !== 'Main Contacts');
        setAddContact({ ...campaignInfo, type: 'list', lists });
    };

    const add = () => {
// if (selectedTab === 1) selectContactOption();

        if (selectedTab === 0) setAddMessage(campaignInfo);
        if (selectedTab === 1) setOptions(addOptions); // setAddContact(campaignInfo);
        if (selectedTab === 2) setAddKeyword(campaignInfo);
    };

    const selectContactOption = async type => {
        const results = await api.lists.fetch();
        const lists = results.filter(result => result.contactsCount);

        if (!lists.length) {
            setConfirmAlert({ 
                description: 'You don\'t have any groups yet. Go to the groups tab to edit them.', 
                cta: 'Done',
                willConfirm: async () => {
                    
                }
            });
            return;
        }

        setAddContact({ ...campaignInfo, type: 'manual', lists });
    };

    const fetchQR = async () => {
        if (!campaign) return;

        let tag = null;

        const tags = await api.tags.fetch();
        const results = tags.filter(tag => tag.campaign._id === campaign._id);
        
        if (!results.length) {
            tag = await api.tags.add(campaign._id, 'qr');
        } else {
            tag = results[0];
        }

        setQRCode('https://t2g.link/opt-in?tag=' + tag.code);
    };

    const saveQR = async () => {
        let tag = null;

        const tags = await api.tags.fetch();
        const results = tags.filter(tag => tag.campaign._id === campaign._id);
        
        if (!results.length) {
            tag = await api.tags.add(campaign._id, 'qr');
        } else {
            tag = results[0];
        }

        // const scan = await api.tags.scan('566571');
        // const sub = await api.tags.subscribe('60464b20746c842674f3f26b', {
        //     phone: '14072837661',
        //     firstName: 'Gabe',
        //     lastName: 'TC yo',
        //     company: 'Geeky'
        // });

        alert(tag.code);


        // await api.tags.remove('60459cdfade594404e9e9f01');

        // await bridge.request('')

        try {
            await bridge.request('saveQRCode', { qrCode: 'https://t2gtemp.geekyagency.com/?tag=' + tag.code });



            setConfirmAlert({ 
                description: 'A QR code has been saved to your camera roll. By scanning it, your customers can opt-in!', 
                cta: 'Done',
                willConfirm: async () => {}
            });
        } catch (e) {
            setConfirmAlert({ 
                description: 'We could not access your camera roll to save this QR code. Make sure you have allowed access and try again.', 
                cta: 'Done',
                willConfirm: async () => {
                    
                }
            });
        }
    }

    const finishConfigure = () => {
        // setAddMessage('initial');
        // container.current.style.opacity = '0.0';
        // setTimeout(() => {
            setAddMessage(null);
            if (messages.length === 0 && contacts.length === 0) showNoContactsAlert();
        // }, 400);
    };

    const addMediaLink = () => {
        setMediaLink({ type: 'link' });
    };

    const saveMediaLink = async media => {
        setMediaLink(null);
        if (!media) return;
        
        const result = await api.medias.add(media);
        setLinkMedia(result);

        // fetchData();
    };

    const changeFile = async e => {
        const file = e.target.files[0];
        if (!file) return;
        
        const data = await fileData.open(file);
        const result = contactsCSV.parse(data);
        // console.log(result.contacts);
        uploadContacts(result.contacts);
    };

    return (
        <Modal navigationTitle={campaignInfo.name} {...props} visible={props.campaign} >
            <div className={style.content}>
                <div className={style.column + ' ' + style.desktop}>
                    <div className={style.messages}>
                        <Empty 
                            content={messages}
                            icon="messages"
                            title="No Messages Yet"
                            message="Looks like there's nothing here. Get started by adding a new message!"
                            cta="New Message"
                            onSelect={() => setAddMessage(campaignInfo)} />
                        {/* {messageItems.length ? <div className={style.messageLine} /> : null} */}
                        {messageItems.map(messageItem)}
                        {messageItems.length ? <KeywordAddItem inline onSelect={() => setAddMessage(campaignInfo)} /> : null}
                    </div>
                </div>
                <div className={style.column}>
                    <SegmentTabs selectedTab={selectedTab} hides={hideFirstTab} tabs={tabs} onSelect={setSelectedTab} />
                    <div className={tabViews[0]}>
                        <div className={style.messages}>
                            <Empty 
                                content={messages}
                                icon="messages"
                                title="No Messages Yet"
                                message="Looks like there's nothing here. Get started by adding a new message!"
                                cta="New Message"
                                onSelect={() => setAddMessage(campaignInfo)} />
                            {/* {messageItems.length ? <div className={style.messageLine} /> : null} */}
                            {messageItems.map(messageItem)}
                            {messageItems.length ? <KeywordAddItem inline onSelect={() => setAddMessage(campaignInfo)} /> : null}
                        </div>
                    </div>
                    <div className={tabViews[1]}>
                        <div className={style.contacts}>
                            {/* <div className={style.addContacts}>
                                <div className={style.addIcon} />
                                <div className={style.title}>Add Contacts</div>
                                <div className={style.desc}>Enter manually or drop CSV file here</div>
                            </div> */}
                            {/* <Empty 
                                content={contacts}
                                icon="contacts"
                                title="No Contacts Yet"
                                message="Nobody is recieving messages from this campaign yet. Add contacts to get started!"
                                cta="Add Contacts"
                                onSelect={add} /> */}
                            <KeywordAddItem onSelect={() => setAddContact({ ...campaignInfo, type: 'manual' })} />
                            <KeywordAddItem title="Add Existing" icon="existing" onSelect={() => setImportContacts({  })} />
                            <KeywordAddItem type="group" onSelect={() => addFromGroup()} />
                            
                            <KeywordAddItem type="upload" types=".csv" noMobileFile onChangeFile={changeFile} onSelect={() => setAddPhoneContacts({})} />
                            <Collection
                                listLayout
                                items={contacts || []}
                                component={ContactManagerItem}
                                onSelect={() => {}} 
                                onAction={(item, action) => action === 'delete' && deleteContact(item)} />
                        </div>
                    </div>
                    <div className={tabViews[2]}>
                        <div className={style.keywords}>
                            {/* <Empty 
                                content={keywords}
                                icon="lock"
                                title="No Keywords Yet"
                                message="Customers can opt-in by texting a keyword of your choice! Add one to get started."
                                cta="Add Keyword"
                                onSelect={add} /> */}
                            <KeywordAddItem onSelect={() => setAddKeyword(campaignInfo)} />
                            <Collection
                                listLayout
                                items={keywords || []}
                                component={KeywordItem}
                                onSelect={() => {}} 
                                onAction={(item, action) => action === 'delete' && deleteKeyword(item.keyword)} />
                        </div>
                    </div>
                    <div className={tabViews[3]}>
                        <div className={style.keywords}>
                            <div className={style.qrContainer}>
                                <QR size={200} value={'' + qrCode} />
                                <p className={style.qrInstruction}>Right click on the QR code to save it wherever you want!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* TODO: we can make some nice smooth renderer for conditionals */}
            <MessageAdd contactsCount={(contacts || []).length} contacts={contacts || []} number={(campaign || {}).number} linkMedia={linkMedia} messageOptions={addMessage} onAddLink={() => setMediaLink({ type: 'link' })} onFinish={finishConfigure} onSave={saveMessage} />
            <ContactImporter importOptions={importContacts} onFinish={finishImport} onSave={uploadContacts} />
            {confirmAlert ? <ConfirmDialog alert={confirmAlert} onFinish={finishConfirmPrompt} /> : null}
            {addContact ? <ContactAddDialog dialog={addContact} onFinish={finishContactPrompt} /> : null}
            {addKeyword ? <KeywordAddDialog dialog={addKeyword} onFinish={finishKeywordPrompt} /> : null}
            {noContactsAlert ? <ConfirmDialog alert={noContactsAlert} onFinish={() => setNoContactsAlert(null)} /> : null}
            {options ? <OptionMenu menu={options} onFinish={() => setOptions(null)} /> : null}
            {mediaLink ? <MediaAddDialog dialog={mediaLink} onFinish={saveMediaLink} /> : null}
            <PhoneContacts visible={addPhoneContacts} onFinish={() => setAddPhoneContacts(null)} onSave={uploadContacts} />
            {/* <OptionMenu menu={{ options: [{ title: 'Take Video', icon: 'video' }, { title: 'Take Photo' }, { title: 'Upload Video', icon: 'video' }, { title: 'Upload Photo' }, { title: 'Add Link' }] }} /> */}
        </Modal>
    );

};

export default CampaignEditor;
