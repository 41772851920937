
import { request } from './';

const fetch = async () => await request('/conversations/fetch', {});

// media support is out of scope
const send = async (conversation, message, media = null) => {
    const to = conversation._id;
    const number = conversation.number;

    if (!to) {
        const error = new Error('Conversation must have recipient');
        error.code = 'recipient-invalid';
        throw error;
    }

    if (!number._id) {
        const error = new Error('Conversation must have number to send from');
        error.code = 'number-invalid';
        throw error;
    }

    if (message.length < 1) return false;

    await request('/conversations/send', { to, number: number._id, message, media: 'default' });
    return true;
};

const read = async conversation => await request('/conversations/read', {
    conversation: conversation._id
});

const archive = async conversation => await request('/conversations/archive', { conversation });

export default { fetch, send, read, archive };
