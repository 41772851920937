
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';

const Empty = props => {

    const visible = props.content && !props.content.length;

    const emptyClass = conditional('Empty', style, { visible });

    return (
        <div className={emptyClass}>
            <div className={style.content}>
                <div className={style.icon} style={{ backgroundImage: 'url(\'placeholder_' + props.icon + '\.svg\')' }} />
                <div className={style.message + ' h3'}>{props.message}</div>
                {props.onSelect ? <div className={'cta'} onClick={props.onSelect}>{props.cta}</div> : null}
            </div>
        </div>
    );

};

export default Empty;
