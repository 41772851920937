
import React from 'react';
import style from './style.module.css';

const MediaManagerItem = props => {

    const localeDate = (new Date(props.data.date)).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })

    return (
        <div className={style.MediaManagerItem} onClick={props.onSelect}>
            {props.data.type === 'photo' ? <img className={style.thumbnail} src={props.data.url} /> : <div className={style.icon} style={{ backgroundImage: 'url(\'media_' + props.data.type + '.svg\')' }} />}
            {/* {JSON.stringify(props.data)} */}
            <div className={style.info}>
                <div className={style.title + ' it1'}>{props.data.title}</div>
                <div className={style.description + ' it2'}>{localeDate}</div>
            </div>
        </div>
    );

};

export default MediaManagerItem;
