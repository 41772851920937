
import React from 'react';
import style from './style.module.css';
import formatPhone from '../../utils/formatPhone';

const HeaderButton = props => {

    return (
        <div className={style.HeaderButton} onClick={props.onSelect} style={props.inline ? { borderBottom: 'none', paddingTop: '16px', transition: '0.3s opacity ease' } : {}}>
            <div className={style.info}>
                <div className={style.titleContainer}>
                    <div className={style.icon + ' ' + style[props.icon]} />
                    <div className={style.title + ' it1'}>{props.title}</div>
                </div>
                {/* <div className={style.description + ' it2'}>{formatPhone(props.data.phone)}</div> */}
            </div>
            {/* <div className={style.icon} onClick={props.onAction('delete')} /> */}
            {/* {props.type==='upload' ? <input className={props.noMobileFile ? style.noMobile : ''} accept={props.types} type="file" onClick={e => e.stopPropagation()} onChange={e => props.onChangeFile && props.onChangeFile(e)} />:null} */}
        </div>
    );

};

export default HeaderButton;
