
import React, { useRef, useEffect, useState } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import { usePropRef } from '../../utils/useProp';

let firstScrollY = 0;

const Modal = props => {

    const ref = useRef();
    const secondaryRef = useRef();
    const confirmRef = useRef();

    const [scrolled, setScrolled] = useState(false);
    const [secondary, setSecondary] = useState(false);
    const [confirm, setConfirm] = useState(false);
    
    const [modalValue, modalRef] = usePropRef(props.visible,
        { opacity: '0.0', pointerEvents: 'none' },
        { opacity: '1.0', pointerEvents: 'auto' });

    const [containerValue, containerRef] = usePropRef(props.visible,
        { transform: 'translateX(40px)' },
        { transform: 'translateX(0px)' });

    const scrollToTop = () => {
        if (ref.current) ref.current.scrollTop = 0;
        if (secondaryRef.current) secondaryRef.current.scrollTop = 0;
        if (confirmRef.current) confirmRef.current.scrollTop = 0;
    };

    useEffect(() => { 
        console.log(props)
        if (props.visible) {
            firstScrollY = window.scrollY;
            scrollToTop();
        }

        if (!props.visible) {
            window.scrollTo(0, firstScrollY);

            setTimeout(() => {
                scrollToTop();
                setSecondary(false);
                setConfirm(false);
            }, 500);
        }
    }, [props.visible]);

    useEffect(() => {
        if (props.secondaryVisible) setSecondary(true);
    }, [props.secondaryVisible]);

    useEffect(() => {
        if (props.confirmVisible) setConfirm(true);
    }, [props.confirmVisible]);

    useEffect(() => { scrollToTop() }, [secondary, confirm]);

    const scrollContent = () => {
        const shouldScroll = ((secondary ? secondaryRef.current.scrollTop : (confirm ? confirmRef.current.scrollTop : ref.current.scrollTop) > 0)) ? true : false;
        if (scrolled !== shouldScroll) setScrolled(shouldScroll);
    };

    const dismiss = () => {
        setSecondary(false);
        setConfirm(false);
        if (props.onSecondaryFinish) props.onSecondaryFinish();
        if (props.onConfirmFinish) props.onConfirmFinish();
    };

    const modalClass = conditional('Modal', style, props);
    const contentClass = conditional('content', style, { hidden: secondary });
    const topBarClass = conditional('topBar', style, { scrolled });
    const mainClass = conditional('main', style, { hidden: secondary });
    const secondaryTopBarClass = conditional('secondaryTop', style, { visible: secondary });
    const secondaryContentClass = conditional('secondaryContent', style, { visible: secondary });
    const confirmTopBarClass = conditional('confirmTop', style, { visible: confirm });
    const confirmContentClass = conditional('confirmContent', style, { visible: confirm });

    const dismissBackground = () => {
        props.onFinish && props.onFinish();
    };

    return (
        <div className={modalClass + ' background'} ref={modalRef} onClick={dismissBackground}>
            <div className={style.container} onClick={e => {console.log('click');e.stopPropagation()}}>
                <div className={topBarClass}>
                    <div className={mainClass}>
                        <div className={style.title + ' t1'}>{props.navigationTitle}</div>
                        <div className={style.back} onClick={() => { props.onFinish && props.onFinish() }} />
                    </div>
                    <div className={secondaryTopBarClass}>
                        <div className={style.back} onClick={() => dismiss(props.onDismiss)} />
                        <div className={style.title + ' t1'}>{props.secondaryNavigationTitle}</div>
                    </div>
                    <div className={confirmTopBarClass}>
                        <div className={style.back} onClick={() => dismiss(props.onDismiss)} />
                        <div className={style.title + ' t1'}>{props.confirmNavigationTitle}</div>
                    </div>
                </div>
                <div className={contentClass} ref={ref} onScroll={scrollContent}>
                    {props.children}
                </div>
                {props.confirm ? <div className={confirmContentClass} ref={confirmRef} onScroll={scrollContent}>
                    {props.confirm({ dismiss, contacts: props.contacts, timing: props.timing })}
                </div> : null}
                {props.secondary ? <div className={secondaryContentClass} ref={secondaryRef} onScroll={scrollContent}>
                    {props.secondary({ dismiss })}
                </div> : null}

                
                {/* <div className={topBarClass}>
                    <div className={mainClass}>
                        <div className={style.title + ' t1'}>{props.navigationTitle}</div>
                        <div className={style.back} onClick={() => { props.onFinish && props.onFinish() }} />
                    </div>
                    <div className={secondaryTopBarClass}>
                        <div className={style.back} onClick={() => dismiss(props.onDismiss)} />
                        <div className={style.title + ' t1'}>{props.secondaryNavigationTitle}</div>
                    </div>
                </div> */}
            </div>
        </div>
    );

};

export default Modal;
