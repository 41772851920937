
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import { Link } from 'react-router-dom';
import conditional from '../../utils/conditional';
import bridge from 'bridge-request';

const emailInvalid = email => email.length < 4 || !email.includes('@') || !email.includes('.');

const SignIn = props => {
    
    const [data, setData] = useState({ email: '', password: '' });
    const [status, setStatus] = useState('waiting');

    const testBridge = async () => {
        console.log('testing bridge');

        // sending errors
        // recieving errors

        try {
            const r2 = await bridge.request('functionName2', null);
            console.log('r2 = ', r2);
        } catch (e) {
            console.log('error', e)
        }
        
        try {
            const r1 = await bridge.request('functionName1', { testModalProp: 'yo' });
            console.log('r1 = ', r1);
        } catch (e) {
            console.log('error', e)
        }

        // const r2 = await bridge.request('functionName2', null);
        // console.log('r2 = ', r2);
    };

    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };

    const containerClass = conditional('container', style, { 
        pending: status === 'pending',
        finished: status === 'finished'
    });

    const enabled = !emailInvalid(data.email) && data.password.length > 4;
    const submitClass = conditional('submit', style, { enabled });

    const submit = async () => {
        setStatus('pending');
        await props.onAuthRequest(data);
        setStatus('waiting');
    };

    return (
        <div className={style.SignIn}>
            <div className={containerClass}>
                <div className={style.title + ' h2'}>Sign In</div>
                <div className={style.desc + ' it2'}>Please sign in with a beta account.</div>
                <input className={'ip2'} type="email" placeholder="Email" onChange={e => updateData('email', e)} />
                <input className={'ip2'} placeholder="Password" type="password" onChange={e => updateData('password', e)} />
                <div className={enabled ? 'cta' : 'cta disabled'} style={{margin: '0px', marginTop: '40px'}} onClick={submit}>Sign In</div>
                {/* <div onClick={() => console.log('buttonclick')}>CLICK</div> */}
            </div>
        </div>
    );

};

export default SignIn;
