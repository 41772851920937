
import React from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import formatPhone from '../../utils/formatPhone';
import ago from '../../utils/ago';

const ConversationItem = props => {

    const contact = props.data.contact || {};
    const messages = props.data.messages || [];
    const unreadCount = props.data.unreadCount || 0;

    const name = contact.firstName || contact.lastName ? contact.firstName + ' ' + contact.lastName : formatPhone(contact.phone);
    const lastMessage = messages[messages.length - 1] || {};
    const date = ago(lastMessage.sendDate);
    const unreadClass = conditional('unread', style, { visible: unreadCount > 0 });

    return (
        <div className={style.CampaignItem + (props.data.selected ? (' ' + style.selected) : '')} onClick={props.onSelect}>
            <div className={unreadClass} />
            <div className={style.info}>
                <div className={style.titleContainer}>
                    <div className={style.title + ' it1'}>{name}</div>
                    <div className={style.date + ' it2'}>{date}</div>
                </div>
                <div className={style.message + ' it2'}>{lastMessage.message}</div>
            </div>
        </div>
    );

};

export default ConversationItem;
