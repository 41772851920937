
const ago = dateString => {
    const date = new Date(dateString);
    const today = new Date();
    const interval = Math.floor(today.getTime() - date.getTime());
    
    const minutes = Math.floor(interval / 60000);
    const hours = Math.floor(interval / 3600000);
    const days = Math.floor(interval / 86400000);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 31);
    const years = Math.floor(days / 365);

    // if (years > 0) return agoString(years, 'y');
    // if (months > 0) return agoString(months, 'm');
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    // if (weeks > 0) return agoString(weeks, 'w');
    // if (days > 0) return agoString(days, 'd');
    // if (hours > 0) return agoString(hours, 'h');
    // if (minutes > 0) return agoString(minutes, 'm');

    // return 'now';
};

// MARK: Utils

const agoString = (value, unit) => {
    const unitString = value !== 1 ? unit : unit;
    return `${value}${unitString}`;
};

export default ago;