
import React, { useEffect } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import useSmoothState from '../../utils/useSmoothState';

const ConfirmDialog = props => {

    const [alert, setAlert] = useSmoothState(null, 0.5);
    const [disabled, setDisabled] = useSmoothState(false, 0.5);

    useEffect(() => {
        if (!props.alert) {
            setAlert('initial');
            setDisabled('initial');
            return;
        }

        setAlert(props.alert);
    }, [props.alert]);

    const finishResult = index => props.onFinish && props.onFinish(index);

    const finish = index => async () => {
        if (index !== 1) {
            finishResult(index);
            return
        }

        if (alertData.willConfirm) {
            setDisabled(true);
            await alertData.willConfirm();
            setDisabled(false);
        }

        finishResult(index);
        if (alertData.onConfirm) alertData.onConfirm();
    };

    const alertClass = conditional('ConfirmDialog', style, { visible: props.alert != null });
    const alertData = alert || {};

    return (
        <div className={alertClass}>
            <div className={style.container}>
                <div className={style.shop} />
                <div className={style.description + ' h3'}>{alertData.description}</div>
                <div className={style.actions}>
                    <div className={'cancel'} onClick={finish(0)}>Cancel</div>
                    <div className={!disabled ? 'button' : 'button disabled'} onClick={finish(1)}>{alertData.cta || 'Done'}</div>
                </div>
            </div>
        </div>
    );

};

export default ConfirmDialog;
