
import React, { useEffect } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';

import Modal from '../Modal';
import ConfirmDialog from '../ConfirmDialog';
import Empty from '../Empty';
import api from '../../api';

import Collection from '../Collection';
import ContactManagerItem from '../ContactManagerItem';
import ContactAddDialog from '../ContactAddDialog';
import OptionMenu from '../OptionMenu';
import PhoneContacts from '../PhoneContacts';


import fileData from '../../utils/fileData';
import contactsCSV from '../../utils/contactsCSV';
import HeaderButton from '../HeaderButton';
import ContactImporter from '../ContactImporter';

const ContactListEditor = props => {

    const [list, setList] = useSmoothState(null, 0.5);
    const [contacts, setContacts] = useSmoothState(null, 0.5);
    const [addContact, setAddContact] = useSmoothState(null, 0.5);
    const [addOptions, setAddOptions] = useSmoothState(null, 0.5);
    const [confirmAlert, setConfirmAlert] = useSmoothState(null, 0.5);
    const [showSecondary, setShowSecondary] = useSmoothState(null, 0.5);
    const [showImporter, setShowImporter] = useSmoothState(null, 0.5);

    useEffect(() => {
        if (!props.list) {
            setList('initial');
            setContacts('initial');
            setAddContact('initial');
            setAddOptions('initial');
            setConfirmAlert('initial');
            setShowImporter('initial');
            return;
        }

        setList(props.list);
    }, [props.list]);

    useEffect(() => { fetchContacts() }, [list]);

    const fetchContacts = async () => {
        if (!list) return;

        const fetchedContacts = await api.lists.fetchContacts(list._id);
        setContacts(fetchedContacts);
    };

    const deleteContact = async contact => {
        setConfirmAlert({ 
            description: 'Are you sure you want to delete this contact? They will not be part of this list going forward.', 
            cta: 'Delete',
            willConfirm: async () => {
                await api.lists.removeContacts(list._id, [contact._id]);
                const updatedContacts = contacts.filter(c => c._id !== contact._id);
                setContacts(updatedContacts);
            }
        });
    };

    const finishContactPrompt = async contact => {
        setAddContact(null);
        if (!contact) return;

        const result = await api.lists.addContacts(list._id, [contact]);
        const updatedContacts = [...contacts, ...result.added].sort((a, b) => a.lastName.localeCompare(b.lastName));

        setContacts(updatedContacts);
        setAddContact(null);
    };

    const saveContacts = async contacts => {
        if (!contacts.length) return;
        
        const result = await api.lists.addContacts(list._id, contacts.map(r => ({ ...r, selected: 'true' })));
        console.log('result = ', result, list, contacts)
        await fetchContacts();
        // const updatedContacts = [...contacts, ...result.added].sort((a, b) => a.lastName.localeCompare(b.lastName));
        
        setShowSecondary(false);
        // setContacts(updatedContacts);
    };

    const listData = list || {};

    const options = [
        {
            title: 'Choose From Existing',
            onSelect: () => {
                setShowImporter(true);
            }
        },
        {
            title: 'Add Manually',
            onSelect: () => {
                setAddContact(listData);
            }
        },
        {
            title: 'Upload',
            onSelect: () => {
                setShowSecondary(true);
            },
            input: true
        }
    ];

    const add = () => {
        setAddOptions(options);
        // setAddContact(listData);
    };

    const uploadContacts = async results => {
        if (!results || !results.length) return;

        // const result = await api.campaigns.addContacts(campaign._id, results);
        // const updatedContacts = [...contacts, ...result.added].sort((a, b) => a.lastName.localeCompare(b.lastName));

        saveContacts(results);
    };

    const changeFile = async e => {
        const file = e.target.files[0];
        if (!file) return;
        
        const data = await fileData.open(file);
        const result = contactsCSV.parse(data);
        // console.log(result.contacts);
        uploadContacts(result.contacts);
    };

    return (
        <Modal navigationTitle={listData.name} {...props} visible={props.list} add={add}>
            <div className={style.content}>
                <div className={style.page}>
                    <div className={style.contacts}>
                        <Empty 
                            content={contacts}
                            icon="contacts"
                            title="No Contacts Yet"
                            message="Nobody is in this list yet. Add your contacts to get started!"
                            cta="Add Contacts"
                            onSelect={add} />
                        <HeaderButton icon="icon" title="Add Contacts" onSelect={() => add()} />
                        <Collection
                            listLayout
                            items={contacts || []}
                            component={ContactManagerItem}
                            onSelect={() => {}} 
                            onAction={(item, action) => action === 'delete' && deleteContact(item)} />
                    </div>
                </div>
            </div>
            <ContactImporter importOptions={showImporter} onFinish={() => setShowImporter(null)} onSave={saveContacts} />
            <ConfirmDialog alert={confirmAlert} onFinish={_ => setConfirmAlert(null)} />
            <ContactAddDialog dialog={addContact} onFinish={finishContactPrompt} />
            <OptionMenu menu={addOptions} onFinish={() => setAddOptions(null)} onChangeFile={changeFile} />
            <PhoneContacts visible={showSecondary} event={props.shareEvent} onFinish={() => setShowSecondary(false)} onSave={saveContacts} />
        </Modal>
    );

};

export default ContactListEditor;
