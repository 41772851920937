
import { request } from './';
import { client, shippingOption, packages, trialOrganization, purchaseOrganization, trialPayment, extraPhone } from './store';

// theres a few steps to go from a brand new account to setting up a campaign
// this file aims to modularize that a bit 
// the tricky thing here is having subscriptions for trials and shit cause we are repurposing api for this

const subscribe = async (product, shipping, billing, payment) => {
    const estimate = await request('/store/subscriptions/estimate', {
        client,
        product,
        version: 'default',
        shipping,
        shippingOption
    });

    const subscription = await request('/store/subscriptions/add', {
        client,
        type: 'place',
        product,
        version: 'default',
        options: {},
        quantity: 1,
        shipping,
        billing,
        payment,
        estimate: estimate.estimate
    });

    if (!subscription.subscriptions) {
        const error = new Error('Error configuring subscription');
        error.code = 'subscription-not-complete';
        throw error;
    }

    return subscription.subscriptions[0];
};

const configureAccount = async user => {
    let shipping = null;
    let billing = null;
    let payment = null;

    const options = await request('/store/cart/options/fetch', { client });

    if (!options.shippingInfo.length) {
        const shippingResult = await request('/store/cart/options/add', { client, update: { shipping: trialOrganization } });
        if (shippingResult.update) shipping = shippingResult.update._id;
    }

    if (!options.billingInfo.length) {
        const billingResult = await request('/store/cart/options/add', { client, update: { billing: trialOrganization } });
        if (billingResult.update) billing = billingResult.update._id;
    }

    if (!options.paymentInfo.length) {
        const paymentResult = await request('/store/cart/options/add', { client, update: { payment: trialPayment } });
        if (paymentResult.update) payment = paymentResult.update._id;

        if (!shipping || !billing || !payment) {
            const error = new Error('Invalid subscription data');
            error.code = 'subscription-data-invalid';
            throw error;
        }

        await subscribe(packages.trial, shipping, billing, payment);

        const phone = (user || {}).phone || '';
        const area = phone.length > 4 ? phone.substr(0, 4) : null;

        if (area) {
            const numbers = await searchNumbers(area);

            if (numbers.numbers.length) {
                await claimNumber(numbers.numbers[0], '');
            }
        }
    }

    return { success: true };
};

const configureAndFetchSubscriptions = async () => {
    await configureAccount(null);
    return await fetchSubscriptions();
};

const checkTrial = async () => {
    const subs = await fetchSubscriptions();
    return subs.filter(sub => sub.path === '/trial-membership').length > 0;
};

const checkPayment = async () => {
    const options = await request('/store/cart/options/fetch', { client });
    return options.paymentInfo.length > 1;
};

const updatePayment = async payment => {
    const organization = purchaseOrganization;
    organization.postalCode = payment.zip;

    // for now we only support cards
    const paymentInfo = {
        type: 'card',
        cc: parseInt(payment.cc),
        cvv: parseInt(payment.cvv),
        expires: payment.exp
    };

    await request('/store/cart/options/add', { client, update: { shipping: organization } });
    await request('/store/cart/options/add', { client, update: { billing: organization } });
    await request('/store/cart/options/add', { client, update: { payment: paymentInfo } });

    return true;
};

const updatePackage = async product => {
    const options = await request('/store/cart/options/fetch', { client });

    if (options.shippingInfo.length < 2 || options.billingInfo.length < 2 || options.paymentInfo.length < 2) {
        const error = new Error('Account payment info is not configured yet');
        error.code = 'account-payment-invalid';
        throw error;
    }
    
    const subs = await fetchSubscriptions();
    const packages = subs.filter(sub => sub.path !== '/extra-phone-number');

    const shipping = options.shippingInfo[options.shippingInfo.length - 1];
    const billing = options.billingInfo[options.billingInfo.length - 1];
    const payment = options.paymentInfo[options.paymentInfo.length - 1];

    console.log(product)

    const newPackage = await subscribe(product, shipping._id, billing._id, payment._id);

    // there should only be 1 package here but may eventually want to support multiple numbers in a package
    for (const pkg of packages) {
        await request('/numbers/migrate/package', { from: pkg._id, to: newPackage._id });
        await request('/store/subscriptions/archive', { client, subscription: pkg._id });
    }

    return newPackage;
};

const archivePackage = async () => {
    const subs = await fetchSubscriptions();
    const packages = subs.filter(sub => sub.path !== '/extra-phone-number');
    // there should only be 1 package here but may eventually want to support multiple numbers in a package
    let dependencies = [];

    for (const pkg of packages) {
        const result = await request('/store/subscriptions/archive', { client, subscription: pkg._id });
        dependencies = [...dependencies, ...(result.dependencies || [])];
    }

    return dependencies;
};

const fetchSubscriptions = async () => {
    const subscriptions = await request('/store/subscriptions/fetch', { client });
    return subscriptions;
};

const fetchNumbers = async () => {
    return await request('/numbers/fetch', {});
};

const searchNumbers = async area => {
    return await request('/numbers/search', { area });
};

const claimNumber = async (phone, name) => {
    const claim = await request('/numbers/claim', { phone, name });

    if (claim.insufficientSubscriptions) {
        const options = await request('/store/cart/options/fetch', { client });

        if (options.shippingInfo.length < 2 || options.billingInfo.length < 2 || options.paymentInfo.length < 2) {
            const error = new Error('Account payment info is not configured yet');
            error.code = 'account-payment-invalid';
            throw error;
        }

        const subs = await fetchSubscriptions();

        const shipping = options.shippingInfo[options.shippingInfo.length - 1];
        const billing = options.billingInfo[options.billingInfo.length - 1];
        const payment = options.paymentInfo[options.paymentInfo.length - 1];

        await subscribe(extraPhone, shipping._id, billing._id, payment._id);
        return await request('/numbers/claim', { phone, name });
    }

    return claim;
};

const deleteNumber = async number => {
    return await request('/numbers/archive', { number });
};

export default { configureAccount, checkTrial, checkPayment, updatePayment, fetchSubscriptions, configureAndFetchSubscriptions, updatePackage, archivePackage, fetchNumbers, searchNumbers, claimNumber, deleteNumber };
