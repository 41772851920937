
import React, { useState } from 'react';
import style from './style.module.css';
import { Link } from 'react-router-dom';
import conditional from '../../utils/conditional';

const emailInvalid = email => (email || '').length < 4 || !(email || '').includes('@') || !(email || '').includes('.');

const SignUp = props => {
    
    const [data, setData] = useState({ email: null, password: null, firstName: null, lastName: null, phone: null, company: null });
    const [status, setStatus] = useState('waiting');

    const updateData = (field, e) => {
        const value = e.target.value;
        console.log(parseInt(value).toString())

        if (field === 'phone') {
            if (value.length && isNaN(parseInt(value))) return;
            setData(data => ({ ...data, [field]: value.length ? parseInt(value).toString() : '' }));
            return;
        }

        setData(data => ({ ...data, [field]: value }));
    };

    const containerClass = conditional('container', style, { 
        pending: status === 'pending',
        finished: status === 'finished'
    });

    const enabled = !emailInvalid(data.email) && (data.password || '').length > 4 && ((data.phone || '').length > 10);
    const submitClass = conditional('submit', style, { enabled });

    const submit = async () => {
        setStatus('pending');
        await props.onSignUpRequest(data);
    };

    return (
        <div className={style.SignUp}>
            <div className={containerClass}>
                <div className={style.title}>Sign Up</div>
                <div className={style.description}>If you have an account, you can sign in <Link to="/sign-in">here</Link>.</div>
                <input className={style.textField} placeholder="Email" value={data.email || ''} type="email" onChange={e => updateData('email', e)} />
                <input className={style.textField} placeholder="Password" value={data.password || ''} type="password" onChange={e => updateData('password', e)} />
                <input className={style.textField} placeholder="First Name" value={data.firstName || ''} onChange={e => updateData('firstName', e)} />
                <input className={style.textField} placeholder="Last Name" value={data.lastName || ''} onChange={e => updateData('lastName', e)} />
                <input className={style.textField} placeholder="Phone" value={data.phone || ''} onChange={e => updateData('phone', e)} />
                <input className={style.textField} placeholder="Company" value={data.company || ''} onChange={e => updateData('company', e)} />
                <div className={submitClass} onClick={submit}>Sign Up</div>
            </div>
        </div>
    );

};

export default SignUp;
