
// import React from 'react';
// import style from './style.module.css';
// import conditional from '../../utils/conditional';
// import formatPhone from '../../utils/formatPhone';
// import ago from '../../utils/ago';

// const ConversationItem = props => {

//     const contact = props.data.contact || {};
//     const messages = props.data.messages || [];
//     const unreadCount = props.data.unreadCount || 0;

//     // const name = contact.firstName || contact.lastName ? contact.firstName + ' ' + contact.lastName : formatPhone(contact.phone);
//     const lastMessage = messages[messages.length - 1] || {};
//     const date = ago(lastMessage.sendDate);
//     const unreadClass = conditional('unread', style, { visible: unreadCount > 0 });

//     return (
//         <div className={style.CampaignItem + (props.data.selected ? (' ' + style.selected) : '')} onClick={props.onSelect}>
//             <div className={unreadClass} />
//             {props.data.type === 'photo' ? <img lazy className={style.thumbnail} src={props.data.url} /> : <div className={style.icon} style={{ backgroundImage: 'url(\'media_' + props.data.type + '.svg\')' }} />}
//             <div className={style.info}>
//                 <div className={style.titleContainer}>
//                     <div className={style.title + ' it1'}>{props.data.title}</div>
//                     {/* <div className={style.date + ' it2'}>{date}</div> */}
//                 </div>
//                 <div className={style.message + ' it2'}>{lastMessage.message}</div>
//             </div>
//         </div>
//     );

// };

// export default ConversationItem;


import React from 'react';
import style from './style.module.css';

const MediaManagerItem = props => {

    const localeDate = (new Date(props.data.date)).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })

    return (
        <div className={style.MediaManagerItem} onClick={props.onSelect}>
            {props.data.type === 'photo' ? <img className={style.thumbnail} src={props.data.url} /> : <div className={style.icon} style={{ backgroundImage: 'url(\'media_' + props.data.type + '.svg\')' }} />}
            {/* {JSON.stringify(props.data)} */}
            <div className={style.info}>
                <div className={style.title + ' it1'}>{props.data.title}</div>
                <div className={style.description + ' it2'}>{localeDate}</div>
            </div>
        </div>
    );

};

export default MediaManagerItem;

