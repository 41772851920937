
import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';
import useSmoothState from '../../utils/useSmoothState';
import { useTabSwitcher } from '../../utils/tabSwitcher';
import BlurModal from '../BlurModal';
import SegmentTabs from '../SegmentTabs';
import conditional from '../../utils/conditional';
import MediaManager from '../MediaManager';
import api from '../../api';
import ConfirmDialog from '../ConfirmDialog';
import Collection from '../Collection';
import ContactManagerItem from '../ContactManagerItem';
import formatPhone from '../../utils/formatPhone';

const followUps = [
    {
        number: '30',
        text: 'days later',
        // timing: { sendAfter: 60 }
        timing: { sendAfter: 30 * 86400 }
    },
    {
        number: '60',
        text: 'days later',
        timing: { sendAfter: 60 * 86400 }
    },
    {
        number: '90',
        text: 'days later',
        timing: { sendAfter: 90 * 86400 }
    }
];

const repeats = [
    {
        title: 'None',
        type: 'none',
        count: 1
    },
    // {
    //     title: 'debug',
    //     type: 'minutely',
    //     count: 5
    // },
    // {
    //     title: 'Daily',
    //     type: 'daily',
    //     count: 1825
    // },
    {
        title: 'Weekly',
        type: 'weekly',
        count: 260
    },
    {
        title: 'Monthly',
        type: 'monthly',
        count: 72
    },
    {
        title: 'Yearly',
        type: 'yearly',
        count: 5
    }
];

const MessageAdd = props => {

    const [messageOptions, setMessageOptions] = useSmoothState(null, 0.5);
    const [message, setMessage] = useSmoothState('', 0.5);
    const [showTokens, setShowTokens] = useSmoothState(false, 0.5);
    const [showMedia, setShowMedia] = useSmoothState(false, 0.5);
    const [showConfirm, setShowConfirm] = useSmoothState(false, 0.5);
    const [medias, setMedias] = useSmoothState([], 0.5);
    const [selectedMedia, setSelectedMedia] = useSmoothState(null, 0.5);
    const [selectedFollowUp, setSelectedFollowUp] = useSmoothState(0, 0.5);
    const [scheduleDate, setScheduleDate] = useSmoothState(null, 0.5);
    const [scheduleRepeat, setScheduleRepeat] = useSmoothState(0, 0.5);
    const [confirmAlert, setConfirmAlert] = useSmoothState(null, 0.5);

    const messageInput = useRef();
    const shouldBlur = useRef(null);

    const [timingTabs, selectedTiming, setSelectedTiming, timingViews] = useTabSwitcher('page', style, ['Send Now', 'Follow Up', 'Schedule']);

    useEffect(() => {
        if (!props.messageOptions) {
            setMessageOptions('initial');
            setMessage('initial');
            setShowTokens('initial');
            setShowMedia('initial');
            setShowConfirm('initial');
            setSelectedMedia('initial');
            setSelectedTiming(0);
            setSelectedFollowUp('initial');
            setScheduleDate('initial');
            setScheduleRepeat('initial');
            return;
        }

        setMessageOptions(props.messageOptions);
        prefillDate();
        fetchMedias();
    }, [props.messageOptions]);

    useEffect(() => {
        if (!props.linkMedia) return;
        setSelectedMedia(props.linkMedia);
        fetchMedias();
    }, [props.linkMedia]);

    const prefillDate = () => {
        let date = new Date();

        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - (offset * 60 * 1000));

        console.log(date.toISOString())

        const dateTime = date.toISOString().split('.')[0];
        const dateComponents = dateTime.split(':');
        dateComponents.pop();
        const scheduleDate = dateComponents.join(':');
        // const time = date.toISOString().split('T')[1];
        // console.log(date, time)
        console.log(scheduleDate);
        setScheduleDate(scheduleDate);
    }

    const fetchMedias = async () => {

        // const testContact = await api.campaigns.addContacts(props.messageOptions._id, [{
        //     phone: '14072837661',
        //     firstName: 'Gabe',
        //     lastName: 'The Coder',
        //     company: 'Geeky'
        // }]);
        // console.log('testcontacts', testContact);

        console.log('fetchmedia');
        // const testcontacts = await api.campaigns.fetchContacts(props.messageOptions._id);
        // console.log('tc= ', testcontacts);
        const testcontacts = await api.campaigns.fetchMessages(props.messageOptions._id);
        console.log('tc= ', testcontacts);



        const results = await api.medias.fetch();
        setMedias(results);

        // const testcontacts = await api.campaigns.fetchContacts(props.messageOptions._id);
        // console.log('tc= ', testcontacts);
    };

    const insertToken = token => () => {
        const position = messageInput.current.selectionStart;
        const update = [message.slice(0, position), token, message.slice(position)].join('');
        messageInput.current.focus();
        setMessage(update);
    };

    const focusTokens = () => {
        shouldBlur.current = false;
        setShowTokens(true);
    };

    const blurTokens = () => {
        shouldBlur.current = true;

        setTimeout(() => {
            if (shouldBlur.current) setShowTokens(false); 
        }, 200);
    };

    const save = async () => {
        const schedule = {
            date: (new Date(scheduleDate)).toISOString(),
            repeatType: repeats[scheduleRepeat].type,
            repeatTimes: repeats[scheduleRepeat].count
        };

        const sequence = selectedTiming === 1 ? followUps[selectedFollowUp].timing : { sendAfter: 0 };

        const newMessage = {
            message,
            media: selectedMedia,
            schedule: selectedTiming === 2 ? schedule : null,
            sequence: selectedTiming !== 2 ? sequence : null
        };

        if (!props.messageOptions._id) return;
        const addedMessage = await api.campaigns.addMessage(props.messageOptions._id, newMessage);

        props.onFinish && props.onFinish(1);
        props.onSave(addedMessage);
    };

    const mediaClass = conditional('media', style, { hidden: showTokens });
    const tokensClass = conditional('tokens', style, { visible: showTokens });
    const attachDeleteClass = conditional('attachDelete', style, { visible: selectedMedia != null });

    const secondary = props => (
        <div className={style.contentMedia}>
            <MediaManager medias={medias} onReload={fetchMedias} onSelect={media => {
                props.dismiss();
                if (media.type === 'video' && !message.includes('Tap the link')) {
                    setMessage(message => message += '\nTap the link below to watch the video!');
                }
                setSelectedMedia(media);
            }} />
        </div>
    );

    const previewMedia = () => {
        if (!selectedMedia) return null;

        if (selectedMedia.type === 'video' || selectedMedia.type === 'photo') {
            return <div className={style.previewMedia} style={{ backgroundImage: 'url(' + selectedMedia.thumbnail + ')' }} />;
        }

        return null;
    };

    const credits = props.contactsCount * (selectedMedia ? 3 : 1);
    const creditText = credits !== 1 ? credits + ' credits' : '1 credit';
    const timeText = scheduleRepeat !== 0 ? repeats[scheduleRepeat].type : 'when sent';

    const timingAction = timing => {
        if (timing === 0) return 'Send Message';
        if (timing === 1) return 'Add Follow Up';
        if (timing === 2) return 'Schedule Message';
    };
    
    const timingHeader = timing => {
        if (timing === 0) return 'right now';
        if (timing === 1) return 'in ' + (selectedFollowUp === 0 ? 30 : (selectedFollowUp === 1 ? 60 : 90)) + ' days';
        if (timing === 2) return 'on ' + (new Date(scheduleDate)).toLocaleDateString() + ' at ' + (new Date(scheduleDate)).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
    };

    const number = formatPhone((props.number || {}).phone || '');

    const confirm = props => (
        <div className={style.contentConfirm}>
            <div className={style.headline}>
                Here's what your contacts will get {timingHeader(props.timing)}. Make sure it looks right!
            </div>
            <div className={style.preview}>
                <div className={style.previewIcon}></div>
                <div className={style.previewInfo}>
                    <div className={style.previewTitle}>
                        <div className={style.previewNumber}>{number}</div>
                        <div className={style.previewTime}>now</div>
                    </div>
                    <div className={style.previewContent}>
                        <p className={style.previewMessage}>{message}</p>
                        {previewMedia()}
                        {/* <div className={style.previewMedia}>
                            {JSON.stringify(selectedMedia)}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={style.contactsHeader}>
                This message will send to the following contacts and cost {creditText} in total when sent.
            </div>
            <div className={style.contacts}>
            <Collection
                listLayout
                items={props.contacts}
                component={ContactManagerItem}
                onSelect={() => {}} 
                hideDelete={true}
                onAction={(item, action) => {}} />
            </div>
            <div className={style.previewActions}>
                {/* <div className={style.cancel + ' cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div> */}
                <div className={style.done + ((message.length) ? ' button' : ' button disabled')} onClick={() => save()}>{timingAction(props.timing)}</div>
            </div>
        </div>
    );

    const followUp = (item, index) => (
        <div className={conditional('followUp', style, { selected: selectedFollowUp === index })} onClick={() => setSelectedFollowUp(index)}>
            <div className={style.number + ' it1'}>{item.number}</div>
            <div className={style.text + ' it2'}>{item.text}</div>
        </div>
    );

    

    console.log('selected = ', selectedMedia);

    const notifyVideo = () => {
        setConfirmAlert({ 
            description: 'Nice video! Telling people to click the link can increase engagement. Would you like to?', 
            cta: 'Add Text',
            willConfirm: async () => {
                
            }
        });
    }

    console.log(props)

    return (
        <BlurModal navigationTitle="Add Message" secondaryNavigationTitle="Attach Media" confirmNavigationTitle="Ready To Send?" {...props} visible={props.messageOptions} secondary={secondary} secondaryVisible={showMedia} confirm={confirm} confirmVisible={showConfirm} onSecondaryFinish={() => setShowMedia(false)} onConfirmFinish={() => setShowConfirm(false)} onConfirmSave={save} timing={selectedTiming}>
            <div className={style.content}>
                <textarea ref={messageInput} className={style.message + ' ip2'} placeholder={'Share something exciting with your customers...'} maxLength="250" value={message} onChange={e => setMessage(e.target.value)} onFocus={focusTokens} onBlur={blurTokens} />
                <div className={style.messageTools}>
                    <div className={style.rotateTools}>
                        <div className={mediaClass}>
                            <div className={style.mediaItem} style={{ flexGrow: selectedMedia ? '1' : '0' }}>
                                <div className={style.attachIcon} style={selectedMedia ? { backgroundImage: 'url(\'/media_' + selectedMedia.type + '.svg\')' } : null} />
                                <div className={style.attachText + ' it2'} onClick={() => props.onAddLink()}>{selectedMedia ? selectedMedia.title : 'Add Link'}</div>
                            </div>
                            <div className={style.mediaItem} style={{ display: selectedMedia ? 'none' : 'flex', marginLeft: '16px' }}>
                                <div className={style.attachLink} style={selectedMedia ? { backgroundImage: 'url(\'/media_' + selectedMedia.type + '.svg\')' } : null} />
                                <div className={style.attachText + ' it2'} onClick={() => setShowMedia(true)}>{selectedMedia ? selectedMedia.title : 'Add Media'}</div>
                            </div>
                            <div className={attachDeleteClass} onClick={() => setSelectedMedia(null)} />
                        </div>
                        <div className={tokensClass}>
                            <div className={style.token + ' b1'} onClick={insertToken('[first name]')}>First Name</div>
                            <div className={style.token + ' b1'} onClick={insertToken('[company]')}>Company</div>
                        </div>
                    </div>
                    <div className={style.counter + ' it2'}>{message.length}/250</div>
                </div>
            </div>
            <SegmentTabs selectedTab={selectedTiming} tabs={timingTabs} onSelect={setSelectedTiming} />
            <div className={timingViews[0]}>
                <div className={style.sendImmediately + ' it2'}>This message will send immediately after a user is added to your campaign.</div>
            </div>
            <div className={timingViews[1]}>
                <div className={style.followUpPicker}>
                    {followUps.map(followUp)}
                </div>
                <div className={style.sendImmediately + ' it2'}>This message will send {followUps[selectedFollowUp].number} days after a recipient joins the campaign.</div>
            </div>
            
            <div className={timingViews[2]}>
                <div className={style.datePicker}>
                    <input className={style.date + ' ip2'} value={scheduleDate} type="datetime-local" onChange={e => setScheduleDate(e.target.value)} />
                    <div className={style.dateIcon}></div>
                </div>
                <div className={style.repeatLabel + ' it2'}>Repeat</div>
                <SegmentTabs selectedTab={scheduleRepeat} tabs={repeats.map(repeat => repeat.title)} onSelect={setScheduleRepeat} /> 
            </div>
            {/* we gotta choose between preview and mobile app rlly  */}
            <div className={style.credits}>
                <div className={style.creditIcon} />
                <div className={style.creditText + ' it2'}>With these contacts, this message will cost you {creditText} in total {timeText}.</div>
            </div>
            <div className={style.actions}>
                <div className={style.cancel + ' cancel'} onClick={() => props.onFinish && props.onFinish(null)}>Cancel</div>
                <div className={style.done + ((message.length) ? ' button' : ' button disabled')} onClick={() => setShowConfirm(true)}>{'Next'}</div>
            </div>
            {confirmAlert ? <ConfirmDialog alert={confirmAlert} onFinish={() => setConfirmAlert(null)} /> : null}
        </BlurModal>
    );

};

export default MessageAdd;
