
import React from 'react';
import style from './style.module.css';
// import { Link } from 'react-router-dom';
import conditional from '../../utils/conditional';

const tabs = [
    {
        icon: 'home',
        path: '/',
        tooltip: 'Home'
        // profile can go here top right
    },
    {
        icon: 'profile',
        path: '/contacts',
        tooltip: 'Contact Groups'
    },
    {
        icon: 'message_2',
        path: '/campaigns',
        tooltip: 'Campaigns'
        // media modal too
        // numbers can go in here
    },
    {
        icon: 'media',
        path: '/media',
        tooltip: 'Media Analytics'
        // numbers in here
    },
    {
        icon: 'inbox',
        path: '/conversations',
        tooltip: 'Inbox'
    }
];

// /, /contacts, /media, /conversations

const TabBar = props => {

    const tab = tab => {
        const className = conditional('tab', style, {
            selected: props.history.location.pathname === tab.path
        });

        return (
            <div className={className} onClick={() => props.history.push(tab.path)}>
                <div className={style.icon} style={{ backgroundImage: 'url("/tab_' + tab.icon + '.svg")' }} />
                <div className={style.tabHighlight}><div className={style.box} /></div>
                <div className={style.tooltip}>{tab.tooltip}</div>
                {tab.path === '/conversations' && props.read > 0 ? <div className={style.badge}></div> : null}
            </div>
        );
    }

    return (
        <div className={style.TabBar}>
            {tabs.map(tab)}
        </div>
    );

};

export default TabBar;
