
import phone from './phone';

const getProperName = attr => {
    const name = attr.toLowerCase();
    if (name.includes('name') && !name.includes('l')) return 'firstName';
    if (name.includes('name')) return 'lastName';
    if (name.includes('phone') || name.includes('number') || name.includes('#')) return 'phone';
    if (name.includes('company')) return 'company';
    return null;
};

const parse = data => {
    const lines = data.trim().split('\n');
    if (lines.length < 2) return null;

    const cols = lines[0].split(',');
    const entries = lines.slice(1, lines.length);

    const parsed = entries.map(entry => {
        const contact = entry.split(',').reduce((acc, item, index) => {
            const name = getProperName(cols[index]);
            return name ? { ...acc, [name]: item } : acc;
        }, {});

        const phoneNumber = phone.format(contact.phone || '') || '';

        return {
            firstName: contact.firstName || '',
            lastName: contact.lastName || '',
            phone: phoneNumber.length < 11 ? '1' + phoneNumber : '',
            company: contact.company || ''
        };
    });

    const contacts = parsed.filter(entry => phone.validate(entry.phone));
    const invalids = parsed.filter(entry => !phone.validate(entry.phone))

    return { contacts, invalids };
};

export default { parse };
